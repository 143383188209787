import React, { useState, useEffect } from "react";
import eyes from "../assets/images/eyes.svg";
import electricity from "../assets/images/electricity.svg";
import generator from "../assets/images/generator.svg";
import solar from "../assets/images/solar_system.svg";
import privateGenerator from "../assets/images/private_generator.svg";
import { Link, useLocation } from "react-router-dom";

function EnergySources() {
  const location = useLocation();
  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [sourcePicked, setSourcePicked] = useState(false);

  const [electricityPicked, setElectricityPicked] = useState(false);
  const [generatorPicked, setGeneratorPicked] = useState(false);
  const [systemPicked, setSystemPicked] = useState(false);
  const [privateGeneratorPicked, setPrivateGeneratorPicked] = useState(false);

  useEffect(() => {
    setRegionName(location.state.regionName);
    setCasaName(location.state.casaName);
    setMohafazaName(location.state.mohafazaName);
    setAreaPicked(location.state.areaPicked);
  }, [location.state]);

  useEffect(() => {
    if (
      electricityPicked ||
      generatorPicked ||
      systemPicked ||
      privateGeneratorPicked
    ) {
      setSourcePicked(true);
    } else {
      setSourcePicked(false);
    }
  }, [
    electricityPicked,
    generatorPicked,
    systemPicked,
    privateGeneratorPicked,
  ]);

  return (
    <div>
      <div className="location-page mx-lg-auto energy-source-page">
        <img
          src={eyes}
          alt=""
          className="mt-lg-5 mt-md-5 mt-2 energy-source-image"
        />
        <h1 className="mt-lg-4 mt-md-4 mb-0">
          Select your energy source & respective monthly consumption averages
        </h1>
        <h3>You can select multiple</h3>
        <div className="energy-source-grid">
          <div
            className={`energy-source-item ${electricityPicked === true &&
              "clicked"}`}
            onClick={() => {
              setElectricityPicked(!electricityPicked);
            }}
          >
            <img src={electricity} alt="" style={{ margin: "0 0 15px 0" }} />
            <p>Electricity of Lebanon</p>
          </div>
          <div
            className={`energy-source-item ${generatorPicked === true &&
              "clicked"}`}
            onClick={() => {
              setGeneratorPicked(!generatorPicked);
            }}
          >
            <img src={generator} alt="" style={{ margin: "0 0 15px 0" }} />
            <p>Generator Subscription</p>
          </div>
          <div
            className={`energy-source-item ${systemPicked === true &&
              "clicked"}`}
            onClick={() => {
              setSystemPicked(!systemPicked);
            }}
          >
            <img src={solar} alt="" style={{ margin: "0 0 5px 0" }} />
            <p>
              Solar System
              <br />
              <i>With or without batteries</i>
            </p>
          </div>

          <div
            className={`energy-source-item ${privateGeneratorPicked === true &&
              "clicked"}`}
            onClick={() => {
              setPrivateGeneratorPicked(!privateGeneratorPicked);
            }}
          >
            <img
              src={privateGenerator}
              alt=""
              style={{ margin: "0 0 35px 0" }}
            />
            <p>Private Generator</p>
          </div>
        </div>

        <Link
          to="/energy-consumption"
          state={{
            areaPicked: areaPicked,
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,

            electricityPicked: electricityPicked,
            generatorPicked: generatorPicked,
            systemPicked: systemPicked,
            privateGeneratorPicked: privateGeneratorPicked,
          }}
        >
          <button
            className=" next-btn mt-2 rounded-pill mb-lg-5 mb-md-5 mb-2"
            type="button"
            disabled={!sourcePicked}
          >
            Next
          </button>
        </Link>
        <div className=" position-sticky footer-style pb-4">
          <p className="text-lg-center text-md-center text-sm-center text-start mb-2 mt-lg-4 mt-md-4 mt-2">
            Try to answer all the questions to get your 5 stars energy
            management report
          </p>

          <div className="d-flex  justify-content-center ">
            <div className={`active box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergySources;
