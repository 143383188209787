import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import lithium from "../assets/images/lithium.svg";
import lead from "../assets/images/lead-acid.svg";
import AGM from "../assets/images/AGM.svg";
import X from "../assets/images/X.svg";

function BatteriesAr() {
  const location = useLocation();
  const navigate = useNavigate();
  const [battery, setBattery] = useState("");
  const [batteryChanged, setBatteryChanged] = useState(false);
  const [lithiumBatteries, setLithiumBatteries] = useState(false);
  const [leadAcidBatteries, setLeadAcidBatteries] = useState(false);
  const [agmBatteries, setAgmBatteries] = useState(false);
  const [lithiumBattInfo, setLithiumBattInfo] = useState(false);
  const [leadBattInfo, setLeadBattInfo] = useState(false);
  const [aGMBattInfo, setAGMBattInfo] = useState(false);
  const [kwh, setKwh] = useState("");
  const [aaa, setAaa] = useState("");

  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");
  const [items, setItems] = useState([]);
  const [tips, setTips] = useState([]);
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [mohafazaName, setMohafazaName] = useState("");
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [simpleApp, setSimpleApp] = useState();

  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setEnergy_manager(location.state.energy_manager);
      setAppliancesUpdates(location.state.appliancesUpdates);
      setAppliance(location.state.appliance);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setSimpleApp(location.state.simpleApp);

      setKwh(location.state.kwh);
      setTips(location.state.tips);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setItems(location.state.items);
      setAaa(location.state.aaa);
    }
  }, [location.state]);

  useEffect(() => {
    console.log(tips);
  }, [tips]);

  useEffect(() => {
    if (batteryChanged && tips.length === 0) {
      navigate("/calculating-ar", {
        state: {
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
          simpleApp: simpleApp,

          items: items,
          kwh: kwh,
          aaa: aaa,
          usd: usd,
          savings: savings,
          battery: battery,
        },
      });
    } else if (batteryChanged && tips.length !== 0) {
      navigate("/tips-ar", {
        state: {
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
          tips: tips,
          simpleApp: simpleApp,

          items: items,
          kwh: kwh,
          aaa: aaa,
          usd: usd,
          savings: savings,
          battery: battery,
        },
      });
    }
  }, [batteryChanged]);

  return (
    <div dir="rtl">
      <div className="batteries-page mx-lg-auto batteries-page-ar">
        <h3 className="mb-0 font-arabic-regular">أوشكت على الانتهاء!</h3>
        <h3 className="font-arabic-regular">
          قبل الحصول على التقدير الشخصي، ما نوع البطارية التي ستختارها؟
        </h3>
        <div className="battery-container">
          {lithiumBattInfo === false ? (
            <>
              <div>
                <div
                  className={`mt-4 battery-type ${lithiumBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setLithiumBatteries(true);
                    setBattery("lithium-ion");
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={lithium} alt="" className="ms-4" />
                    <h1 className="mb-0 font-arabic-regular">
                      بطاريات الليثيوم
                    </h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setLithiumBattInfo(true);
                  }}
                  className="learn-more-text font-arabic-regular"
                >
                  تعرف على المزيد حول مواصفات بطاريات الليثيوم{" "}
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setLithiumBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4 className="font-arabic-regular">بطاريات الليثيوم</h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title font-arabic-regular">عمر</p>
                  <p className="batt-properties font-arabic-regular">
                    تصل إلى 10 سنوات
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">صيانة</p>
                  <p className="batt-properties font-arabic-regular">حر</p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">سعر</p>
                  <p className="batt-properties font-arabic-regular">
                    ما يصل إلى ٤x
                  </p>
                  <p className="comparison-text font-arabic-regular">
                    بالمقارنة مع بطاريات Lead Acid أو AGM
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">وزن</p>
                  <p className="batt-properties font-arabic-regular">
                    {" "}
                    4x اخف وزناً
                  </p>
                  <p className="comparison-text font-arabic-regular">
                    {" "}
                    بالمقارنة مع بطاريات Lead Acid أو AGM
                  </p>
                </div>
              </div>
            </div>
          )}

          {leadBattInfo === false ? (
            <>
              <div>
                <div
                  className={`battery-type ${leadAcidBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setBattery("lead_acid");
                    setLeadAcidBatteries(true);
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={lead} alt="" className="ms-4" />
                    <h1 className="d-inline-block mb-0 font-arabic-regular">
                      بطاريات الرصاص الحمضية
                    </h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setLeadBattInfo(true);
                  }}
                  className="learn-more-text font-arabic-regular"
                >
                  تعرف على المزيد حول مواصفات بطاريات الرصاص الحمضية{" "}
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={(e) => {
                  setLeadBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4 className="font-arabic-regular">بطاريات الرصاص الحمضية</h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title font-arabic-regular">عمر</p>
                  <p className="batt-properties font-arabic-regular">
                    3-5 سنوات
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">صيانة</p>
                  <p className="batt-properties font-arabic-regular">مطلوب</p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">سعر</p>
                  <p className="batt-properties font-arabic-regular">رخيص</p>
                  <p className="comparison-text font-arabic-regular ">
                    بالمقارنة مع بطاريات الليثيوم
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">مساحة</p>
                  <p className="batt-properties font-arabic-regular">4x</p>
                  <p className="comparison-text font-arabic-regular">
                    ما يعادلها في بطاريات الليثيوم
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">
                    الشحن الزائد
                  </p>
                  <p className="batt-properties font-arabic-regular">تتحمل</p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">
                    التفريغ الذاتي
                  </p>
                  <p className="batt-properties font-arabic-regular">
                    معدل مرتفع
                  </p>
                </div>
              </div>
            </div>
          )}

          {aGMBattInfo === false ? (
            <>
              <div>
                <div
                  className={`battery-type ${agmBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setBattery("agm");
                    setAgmBatteries(true);
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={AGM} alt="" className="ms-4" />
                    <h1 className="d-inline-block mb-0 font-arabic-regular">
                      بطاريات الألياف الزجاجية الممتصة (AGM)
                    </h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setAGMBattInfo(true);
                  }}
                  className="learn-more-text font-arabic-regular"
                >
                  تعرف على المزيد حول مواصفات بطاريات الألياف الزجاجية الممتصة{" "}
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setAGMBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4 className="font-arabic-regular">
                بطاريات الألياف الزجاجية الممتصة (AGM)
              </h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title font-arabic-regular">عمر</p>
                  <p className="batt-properties font-arabic-regular">
                    3-5 سنوات
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">صيانة</p>
                  <p className="batt-properties font-arabic-regular">حر</p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">سعر</p>
                  <p className="batt-properties font-arabic-regular">رخيص</p>
                  <p className="comparison-text font-arabic-regular">
                    بالمقارنة مع بطاريات الليثيوم
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">مساحة</p>
                  <p className="batt-properties font-arabic-regular">4x</p>
                  <p className="comparison-text font-arabic-regular ">
                    ما يعادلها في بطاريات الليثيوم
                  </p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">
                    الشحن الزائد
                  </p>
                  <p className="batt-properties font-arabic-regular">حساس</p>
                </div>
                <div>
                  <p className="batt-info-title font-arabic-regular">
                    التفريغ الذاتي
                  </p>
                  <p className="batt-properties font-arabic-regular">
                    معدل منخفض
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex padding-bottom justify-content-center position-sticky background-box">
          <div
            className={`active box`}
            onClick={() => {
              navigate(-3);
            }}
          ></div>
          <div
            className={`active box`}
            onClick={() => {
              navigate(-2);
            }}
          ></div>
          <div
            className={`active box`}
            onClick={() => {
              navigate(-1);
            }}
          ></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
        </div>
      </div>
    </div>
  );
}

export default BatteriesAr;
