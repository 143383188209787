import React, { useState, useEffect } from "react";
import daytime from "../../assets/images/daytime.svg";
import nighttime from "../../assets/images/nighttime.svg";
function AppliancesCard({
  title,
  id,
  appliancesUpdates,
  setAppliancesUpdates,
  objectNew,
}) {
  const [active, setActive] = useState(true);
  const [activeHrs, setActiveHrs] = useState(false);
  const [daytime_qty, setDaytime_qty] = useState(0);
  const [nighttime_qty, setNighttime_qty] = useState(0);
  const [nightHrsClicked, setNightHrsClicked] = useState(false);
  const [dayHrsClicked, setDayHrsClicked] = useState(false);

  function allowOnlyNumbersNight(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      const newArr = appliancesUpdates.map((obj) => {
        if (obj.id === parseInt(id)) {
          return {
            ...obj,
            nighttime_hrs: e.target.value,
          };
        }
        return obj;
      });

      appliancesUpdates.map((obj) => {
        if (obj.id === parseInt(id)) {
          objectNew.push({ ...obj, nighttime_hrs: e.target.value });
        }
        return obj;
      });
      setAppliancesUpdates(newArr);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  function allowOnlyNumbersDay(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      const newArr = appliancesUpdates.map((obj) => {
        if (obj.id === parseInt(id)) {
          return {
            ...obj,
            daytime_hrs: e.target.value,
          };
        }
        return obj;
      });
      appliancesUpdates.map((obj) => {
        if (obj.id === parseInt(id)) {
          objectNew.push({ ...obj, daytime_hrs: e.target.value });
        }
        return obj;
      });
      setAppliancesUpdates(newArr);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  //
  function increaseHrs() {
    const newArr = appliancesUpdates.map((obj) => {
      if (obj.id === parseInt(id)) {
        return {
          ...obj,
          daytime_hrs: "12",
          nighttime_hrs: "12",
        };
      }
      return obj;
    });
    appliancesUpdates.map((obj) => {
      if (obj.id === parseInt(id)) {
        objectNew.push({ ...obj, daytime_hrs: "12", nighttime_hrs: "12" });
      }
      return obj;
    });
    setAppliancesUpdates(newArr);
  }

  useEffect(() => {
    if (daytime_qty > 0) {
      setDayHrsClicked(true);
    } else {
      setDayHrsClicked(false);
    }
  }, [daytime_qty]);

  useEffect(() => {
    if (nighttime_qty > 0) {
      setNightHrsClicked(true);
    } else {
      setNightHrsClicked(false);
    }
  }, [nighttime_qty]);

  return (
    <div className="appliance-border m-3 mt-2">
      <div className="d-flex justify-content-between mx-2 mt-3">
        <p className="card-title ms-3">{title}</p>
        <div>
          <div className="d-flex">
            <p className={`font-10 mb-0 pt-1 ${active === false && "active"}`}>
              Per week
            </p>
            <div className="form-switch mx-1 mt-1">
              <input
                className="form-check-input check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                defaultChecked
                onClick={(e) => {
                  setActive(e.target.checked);
                  const newArr = appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      return {
                        ...obj,
                        week_day: e.target.checked === true ? "day" : "week",
                      };
                    }
                    return obj;
                  });
                  appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      objectNew.push({
                        ...obj,
                        week_day: e.target.checked === true ? "day" : "week",
                      });
                    }
                    return obj;
                  });
                  setAppliancesUpdates(newArr);
                }}
              />
            </div>
            <p className={`font-10 mb-0 pt-1 ${active === true && "active"}`}>
              Per day
            </p>
          </div>
          {/*  */}
          {/*  */}
          <div className="d-flex align-items-center float-end">
            <div className="form-switch mx-1">
              <input
                className="form-check-input check-input load-input"
                type="checkbox"
                name="daytime_hrs"
                id="flexSwitchCheckChecked"
                onClick={(e) => {
                  setActiveHrs(!activeHrs);
                  increaseHrs();
                }}
              />
            </div>
            <p
              className={`load-text font-10 mb-0 pt-1 ${activeHrs === true &&
                "active"}`}
            >
              Continuous
              <br /> Load (24h)
            </p>
          </div>
          {/*  */}
        </div>
      </div>

      <div className="appliances-card-grid">
        <div className="daytime-section">
          <div className="text-center mt-2 mb-3 pb-1">
            <div className="d-inline-block me-2 img-icon mt-1">
              <img src={daytime} alt="" />
            </div>
            <div className="d-inline-block daytime-text">Daytime</div>
          </div>

          {/*  */}
          <div className="d-flex align-items-center justify-content-center mb-3">
            <button
              className="btn-next ps-0"
              type="button"
              onClick={() => {
                if (daytime_qty > 0) {
                  setDaytime_qty(daytime_qty - 1);
                  const newArr = appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      return {
                        ...obj,
                        daytime_qty: daytime_qty - 1,
                      };
                    }
                    return obj;
                  });
                  appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      objectNew.push({
                        ...obj,
                        daytime_qty: daytime_qty - 1,
                      });
                    }
                    return obj;
                  });
                  setAppliancesUpdates(newArr);
                }
              }}
            >
              <div className="daytime-circle">-</div>
            </button>
            <p className="align-items-center d-flex count-number mb-0">
              {daytime_qty}
            </p>

            <button
              className="btn-next"
              type="button"
              onClick={() => {
                setDaytime_qty(daytime_qty + 1);
                const newArr = appliancesUpdates.map((obj) => {
                  if (obj.id === parseInt(id)) {
                    return {
                      ...obj,
                      daytime_qty: daytime_qty + 1,
                    };
                  }
                  return obj;
                });
                appliancesUpdates.map((obj) => {
                  if (obj.id === parseInt(id)) {
                    objectNew.push({ ...obj, daytime_qty: daytime_qty + 1 });
                  }
                  return obj;
                });
                setAppliancesUpdates(newArr);
              }}
            >
              <div className="daytime-circle">+</div>
            </button>
          </div>

          {/*  */}
          {dayHrsClicked || activeHrs ? (
            <div className="d-flex mx-2  mb-3 justify-content-center">
              <div className="hrs-input-div">
                <input
                  placeholder="0"
                  min={0}
                  type="tel"
                  name="daytime_hrs"
                  className="hrs-input"
                  defaultValue={activeHrs ? 12 : ""}
                  onKeyPress={(e) => {
                    if (e.code === "Minus") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    allowOnlyNumbersDay(e);
                  }}
                />
              </div>
              <p className="hrs mb-0 ps-1 pt-0">Hrs</p>
            </div>
          ) : (
            ""
          )}
          {/*  */}
        </div>
        <div className="nighttime-section">
          <div className="text-center mt-2 mb-3 pb-1">
            <div className="d-inline-block me-2 img-icon-night mt-1 ">
              <img src={nighttime} alt="" />
            </div>
            <div className="d-inline-block nighttime-text">Nighttime</div>
          </div>

          <div className="d-flex align-items-center justify-content-center mb-3">
            <button
              className="btn-next ps-0"
              type="button"
              onClick={() => {
                if (nighttime_qty > 0) {
                  setNighttime_qty(nighttime_qty - 1);
                  const newArr = appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      return {
                        ...obj,
                        nighttime_qty: nighttime_qty - 1,
                      };
                    }
                    return obj;
                  });
                  appliancesUpdates.map((obj) => {
                    if (obj.id === parseInt(id)) {
                      objectNew.push({
                        ...obj,
                        nighttime_qty: nighttime_qty - 1,
                      });
                    }
                    return obj;
                  });
                  setAppliancesUpdates(newArr);
                }
              }}
            >
              <div className="nighttime-circle">-</div>
            </button>
            <p className=" align-items-center d-flex nighttime-number mb-0">
              {nighttime_qty}
            </p>
            <button
              className="btn-next"
              type="button"
              onClick={() => {
                setNighttime_qty(nighttime_qty + 1);
                const newArr = appliancesUpdates.map((obj) => {
                  if (obj.id === parseInt(id)) {
                    return {
                      ...obj,
                      nighttime_qty: nighttime_qty + 1,
                    };
                  }
                  return obj;
                });
                appliancesUpdates.map((obj) => {
                  if (obj.id === parseInt(id)) {
                    objectNew.push({
                      ...obj,
                      nighttime_qty: nighttime_qty + 1,
                    });
                  }
                  return obj;
                });
                setAppliancesUpdates(newArr);
              }}
            >
              <div className="nighttime-circle">+</div>
            </button>
          </div>

          {/*  */}
          {nightHrsClicked || activeHrs ? (
            <div className="d-flex mx-2 mb-3 justify-content-center">
              <div className="hrs-input-div">
                <input
                  placeholder="0"
                  min={0}
                  type="tel"
                  name="nighttime_hrs"
                  className="hrs-nighttime-input"
                  defaultValue={activeHrs ? 12 : ""}
                  onKeyPress={(e) => {
                    if (e.code === "Minus") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    allowOnlyNumbersNight(e);
                  }}
                />
              </div>

              <p className="hrs mb-0 ps-1 pt-0">Hrs</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default AppliancesCard;
