import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import managerImg from "../assets/images/energy-manager.svg";

function EnergyManager() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataEntered, setDataEntered] = useState(false);
  const [yesClicked, setYesClicked] = useState(false);
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [noClicked, setNoClicked] = useState(false);
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [items, setItems] = useState();
  const [electricityNewPicked, setElectricityNewPicked] = useState(false);
  const [generatorNewPicked, setGeneratorNewPicked] = useState(false);
  const [systemNewPicked, setSystemNewPicked] = useState(false);
  const [privateGeneratorNewPicked, setPrivateGeneratorNewPicked] = useState(
    false
  );

  useEffect(() => {
    setElectricityNewPicked(location.state.electricityPicked);
    setGeneratorNewPicked(location.state.generatorPicked);
    setSystemNewPicked(location.state.systemPicked);
    setPrivateGeneratorNewPicked(location.state.privateGeneratorPicked);
  }, [location.state]);

  useEffect(() => {
    if (location.state.items) {
      setItems(location.state.items);
    }
  }, [location.state.items]);

  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
    }
  }, [location.state]);

  useEffect(() => {
    if (regionName === "" && !location.state) {
      navigate("/");
    }
  }, [regionName]);

  function allowOnlyNumbers(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      setAge(e.target.value);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  return (
    <div>
      <div className="energy-manager-page mx-lg-auto">
        <div className="energymanager-image-height mb-4">
          <img src={managerImg} alt="" className="mt-lg-5 mt-md-5 mt-4" />
        </div>
        <h1>Do you feel like you are the energy manager of the house?</h1>
        <form
          className={`mt-3 ${noClicked ? "mb-0" : ""} ${
            yesClicked ? "margin-bottom" : ""
          }`}
        >
          <input
            type="radio"
            id="yes"
            name="energy_manager"
            value="yes"
            className="form-check-input manager-input"
            onClick={() => {
              setEnergy_manager("yes");
              setYesClicked(true);
              setNoClicked(false);
              setDataEntered(true);
              setGender("male");
            }}
          />
          <label htmlFor="yes" className="me-5 pt-2">
            Yes
          </label>
          <input
            type="radio"
            id="no"
            name="energy_manager"
            value="no"
            className="form-check-input manager-input"
            onClick={() => {
              setEnergy_manager("no");
              setNoClicked(true);
              setYesClicked(false);
              setDataEntered(true);
              setGender("");
              setAge("");
            }}
          />
          <label htmlFor="no">No</label>
          <div className="mt-4 yes-section">
            {yesClicked ? (
              <>
                <span className="me-2 pt-2">Gender</span>
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  className="gender-input form-check-input"
                  onClick={(e) => {
                    setGender(e.target.value);
                  }}
                  defaultChecked
                />
                <label htmlFor="male" className="me-2 ps-2">
                  M
                </label>
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  className="ms-2 gender-input form-check-input"
                  onClick={(e) => {
                    setGender(e.target.value);
                  }}
                />
                <label htmlFor="female" className="ps-2">
                  F
                </label>
                <span className="me-2 ms-4">Age</span>
                <input
                  min={0}
                  type="tel"
                  name="age"
                  id="age"
                  placeholder="38"
                  className={`energy-input font-size ${age &&
                    `border-selected-input`}`}
                  onChange={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onKeyPress={(e) => {
                    if (e.code === "Minus") {
                      e.preventDefault();
                    }
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="no-section mb-lg-5 mb-md-5 mb-sm-5 mb-0">
            {noClicked ? (
              <>
                <p className="mb-2">If not who is? </p>
                <button
                  type="button"
                  className={`rounded-pill  location-btn ${manager &&
                    "rounded-pill location-btn-checked"}`}
                >
                  <select
                    id="disabledSelect"
                    name="manager"
                    className="form-select"
                    onChange={(e) => {
                      setManager(e.target.value);
                    }}
                  >
                    <option>Please select</option>
                    <option value="mother">Mother</option>
                    <option value="father">Father</option>
                    <option value="wife">Wife</option>
                    <option value="husband">Husband</option>
                    <option value="sister">Sister</option>
                    <option value="brother">Brother</option>
                    <option value="daughter">Daughter</option>
                    <option value="roommate">Room mate</option>
                    <option value="partner">Partner</option>
                    <option value="other">Other</option>
                  </select>
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </form>
        {/*  */}

        {/*  */}
        {systemNewPicked === false ? (
          <>
            <Link
              to="/feedback"
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <button
                className={` ${
                  noClicked
                    ? "next-btn rounded-pill margin-btn"
                    : "next-btn rounded-pill margin"
                }`}
                type="button"
                disabled={!dataEntered}
              >
                Next
              </button>
            </Link>
            <Link
              to="/feedback"
              className={"text-muted text-decoration-none"}
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <p className="skip-text pb-3 mt-lg-5 mt-md-5 pt-4">Skip</p>
            </Link>
          </>
        ) : (
          ""
        )}
        {/*  */}

        {systemNewPicked === true &&
        privateGeneratorNewPicked === false &&
        generatorNewPicked === false &&
        electricityNewPicked === false ? (
          <>
            <Link
              to="/feedback-solar"
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <button
                className="next-btn mt-4 rounded-pill mb-lg-5 mb-md-5 mb-2"
                type="button"
                disabled={!dataEntered}
              >
                Next
              </button>
            </Link>
            <Link
              to="/feeback-solar"
              className={"text-muted text-decoration-none"}
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <p className="skip-text pb-3 mt-lg-5 mt-md-5 pt-4">Skip</p>
            </Link>
          </>
        ) : (
          ""
        )}
        {systemNewPicked === true &&
        (privateGeneratorNewPicked === true ||
          generatorNewPicked === true ||
          electricityNewPicked === true) ? (
          <>
            <Link
              to="/feedback-sources"
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <button
                className="next-btn mt-4 rounded-pill mb-lg-5 mb-md-5 mb-2"
                type="button"
                disabled={!dataEntered}
              >
                Next
              </button>
            </Link>
            <Link
              to="/feedback-sources"
              className={"text-muted text-decoration-none"}
              state={{
                rooms: rooms,
                people: people,
                age: age,
                gender: gender,
                manager: manager,
                energy_manager: energy_manager,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                areaPicked: areaPicked,
                items: items,
              }}
            >
              <p className="skip-text pb-3 mt-lg-5 mt-md-5 pt-4">Skip</p>
            </Link>
          </>
        ) : (
          ""
        )}

        <div className="mt-4 pt-4 d-flex padding-bottom justify-content-center">
          <div
            className={`active box`}
            onClick={() => {
              navigate(-1);
            }}
          ></div>
          <div className={`active box`}></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
        </div>
      </div>
    </div>
  );
}

export default EnergyManager;
