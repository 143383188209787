import React, { useState, useEffect } from "react";
import ResultCardAr from "./shared/ResultCardAr";
import battery from "../assets/images/battery.svg";
import system from "../assets/images/system.svg";
import roof from "../assets/images/roof.svg";
import space from "../assets/images/space.svg";
import inverter from "../assets/images/inverter.svg";
import amps from "../assets/images/amps.svg";
import download from "../assets/images/download.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import share from "../assets/images/share.svg";
import html2canvas from "html2canvas";
import X from "../assets/images/X.svg";

function ResultsAr() {
  const location = useLocation();
  const navigate = useNavigate();

  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [runningCurrent, setRunningCurrent] = useState("");
  const [value, setValue] = useState(runningCurrent);
  const [noRegion, setNoRegion] = useState(false);
  const [mohafazaCasa, setMohafazaCasa] = useState(false);
  const [modalDefaultOpen, setModalDefaultOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newValue, setNewValue] = useState(((value - 0) / (300 - 0)) * 100);
  const [data, setData] = useState("");
  const [batteryBankSize, setBatteryBankSize] = useState("");
  const [maxAmp, setMaxAmp] = useState("");
  const [minInverterSize, setMinInverterSize] = useState("");
  const [minLandRequired, setMinLandRequired] = useState("");
  const [pvSize, setPvSize] = useState("");
  const [spaceVolumeNeeded, setSpaceVolumeNeeded] = useState("");
  const [maxAmpere, setMaxAmpere] = useState("");
  const [linkID, setLinkID] = useState("");
  const [dod, setDod] = useState("");
  const [duration, setDuration] = useState("");
  const [hideButtons, setHideButtons] = useState(false);
  const [durationValue, setDurationValue] = useState("");
  const [tips, setTips] = useState([]);
  const [simpleApp, setSimpleApp] = useState("");
  const [peopleText, setPeopleText] = useState("");
  const [roomsText, setRoomsText] = useState("");

  useEffect(() => {
    setValue(runningCurrent);
  }, [runningCurrent]);
  useEffect(() => {
    setMaxAmpere(maxAmp);
  }, [maxAmp]);

  useEffect(() => {
    setSimpleApp(location.state.newSimpleApp);
  }, [location.state]);

  useEffect(() => {
    if (data) {
      if (data[0].battery_bank_size) {
        setBatteryBankSize(data[0].battery_bank_size);
      }
      setMaxAmp(data[0].max_amp);
      setMinInverterSize(data[0].min_inverter_size);
      setMinLandRequired(data[0].min_land_required);
      setPvSize(data[0].pv_size);
      if (data[0].space_volume_needed) {
        setSpaceVolumeNeeded(data[0].space_volume_needed);
      }
      setRunningCurrent(data[0].running_current);
      setDod(data[0].dod);
      setTips(data[0].tips);
      setLinkID(data[0].results_link);
    }
  }, [data]);

  const results = [
    {
      number: <p className="mb-0 card-number">{pvSize}</p>,
      unit: "كيلوواط الذروة ",
      image: <img src={system} alt="" className="pt-3 result-card-image" />,
      title: " كحد أدنى بالنسبة لحجم النظام الكهروضوئي",
      text:
        "الحد الأدنى من مساحة السطح أو الأرض المطلوبين: المساحة التي ستتم تغطيتها بكافة الألواح الشمسية بما في ذلك توجيهها لتجنب التظليل",
    },
    {
      number: <p className="mb-0 card-number">{batteryBankSize}</p>,
      unit: "كيلوواط ساعة",
      image: <img src={battery} alt="" className="pt-3 result-card-image" />,
      title: "بنك بطارية ليثيوم",
      text:
        "A type of rechargeable battery which uses the reversible reduction of lithium ions to store energy",
    },

    {
      number: <p className="mb-0 card-number">{minLandRequired}</p>,
      unit: "م2",
      image: <img src={roof} alt="" className="pt-3 result-card-image" />,
      title: "مساحة قدرها تقريباً للسقف أو الأرض المطلوبين",
      text:
        "الحد الأدنى من مساحة السطح أو الأرض المطلوبين: المساحة التي ستتم تغطيتها بكافة الألواح الشمسية بما في ذلك توجيهها لتجنب التظليل",
    },
    // {
    //   number: <p className="mb-0 card-number direction">{spaceVolumeNeeded}</p>,
    //   unit: "م3",
    //   image: (
    //     <img src={space} alt="" className="pt-3 result-card-image direction" />
    //   ),
    //   title: " المساحة اللازمة للبطاريات بحجم تقريبي ",
    //   text:
    //     "الحجم التقريبي للمساحة اللازمة للبطاريات: يجب أن تكون المساحة اللازمة لتركيب البطاريات جيدة التهوئة وبعيدة عن الماء (المطر) والشمس",
    // },
    {
      number: <p className="mb-0 card-number">{minInverterSize}</p>,
      unit: "كيلوواط",
      image: <img src={inverter} alt="" className="pt-3 result-card-image" />,
      title: "حجم العاكس  كحد أدنى",
      text:
        "الحد الأدنى لحجم العاكس: أقصى طاقة يمكن استهلاكها قبل انقطاع التيار الكهربائي",
    },
    {
      number: <p className="mb-0 card-number">{maxAmp}</p>,
      unit: "أمبير",
      image: <img src={amps} alt="" className="pt-3 result-card-image" />,
      title: "توفير أمبير كحد أقصى من العاكس",
      text:
        "الحد الأقصى من الأمبيرات التي يتم توفيرها من العاكس: الحد الأقصى من التيار الذي يمكن الحصول عليه قبل انقطاع التيار الكهربائي",
    },
  ];
  var min = 0;
  var max = maxAmpere;
  function calculate(e) {
    setValue(e.target.value);
  }
  useEffect(() => {
    setNewValue(((value - min) / (max - min)) * 100);
  }, [value]);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `I just used this solar calculator from UNDP to get an energy management estimate for my home, try it here: https://solarcalculator.energyhub-lb.com/#/results-ar/${linkID} `
    );
    // alert("Text copied");
  };

  useEffect(() => {
    if (location.state) {
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.regionName === "All") {
      setNoRegion(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (
      location.state &&
      location.state.mohafazaName === location.state.casaName
    ) {
      setMohafazaCasa(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (regionName === "" && !location.state) {
      navigate("/");
    }
  }, [regionName]);

  useEffect(() => {
    setDurationValue(value);
  }, [value]);

  useEffect(() => {
    setDuration((batteryBankSize * 1000 * dod) / (220 * durationValue));
  }, [value, dod, batteryBankSize, durationValue]);

  function screenshot() {
    setHideButtons(true);
    const input = document.getElementById("results-page");
    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let a = document.createElement("a");
        a.download = "undp-calculator-results.jpg";
        a.href = canvas.toDataURL("image/jpg");
        a.click("JPG", 0, 0, imgWidth, imgHeight);
        setHideButtons(false);
      });
    }, 1000);
  }

  useEffect(() => {
    if (people && rooms) {
      setPeopleText(` و ${people} أشخاص`);
    } else if (people && !rooms) {
      setPeopleText(` ويأوي ${people} أشخاص`);
    }
  }, [people, rooms]);

  useEffect(() => {
    if (rooms) {
      setRoomsText(` ويتكون من ${rooms} غرف`);
    }
  }, [rooms]);

  return (
    <div>
      <div
        className="results-page pb-3 mx-lg-auto direction position-relative"
        id="results-page"
      >
        {modalOpen ? (
          <div className="averagesModal" dir="rtl">
            <h4 className="font-arabic-regular">المعدلات في منطقتك هي:</h4>
            <button
              type="button"
              className="btn btn-submit"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <img src={X} alt="" />
            </button>
            <div className="averages">
              <div className="averages_first_row font-arabic-regular">
                <h4 className="font-arabic-regular">جهاز</h4>
                <p>مكيف &nbsp;الهواء (9000BTU)</p>
                <p>مكيف &nbsp;الهواء (12000BTU)</p>
                <p>سخان الماء الكهربائي</p>
                <p>مضخة بئر</p>
                <p>تلفزيون</p>
                <p>جهاز ألعاب الكترونية</p>
                <p>راوتر انتيرنت منزلي</p>
                <p>هاتف منزلي</p>
                <p>كمبيوتر محمول</p>
                <p>شاحن الهاتف</p>
                <p>براد /ثلاجة</p>
                <p>ميكروويف</p>
                <p>غلاية كهربائية</p>
                <p>موزع مياه</p>
                <p>لمبة LED متوسطة</p>
                <p>غسالة</p>
              </div>
              <div className="data">
                <h4 className="font-arabic-regular justify-content-center">
                  القوة الكهربائية القصوى
                </h4>
                <p>1500</p>
                <p>2300</p>
                <p>1500</p>
                <p>750</p>
                <p>150</p>
                <p>200</p>
                <p>15</p>
                <p>5</p>
                <p>100</p>
                <p>7</p>
                <p>300</p>
                <p>2500</p>
                <p>1800</p>
                <p>200</p>
                <p>40</p>
                <p>800</p>
              </div>
              <div className="data">
                <h4 className="font-arabic-regular justify-content-center">
                  متوسط ​​الاستخدام اليومي (ساعة)
                </h4>
                <p>5</p>
                <p>5</p>
                <p>1</p>
                <p>0.5</p>
                <p>6</p>
                <p>2</p>
                <p>24</p>
                <p>24</p>
                <p>6</p>
                <p>24</p>
                <p>24</p>
                <p>0.1</p>
                <p>0.1</p>
                <p>24</p>
                <p>12</p>
                <p>1</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {simpleApp ? (
          <p className="averageText mx-4 font-arabic-regular">
            تعتمد النتائج أدناه على متوسط ​​منطقتك مع الاستمرار حمولة.&nbsp;
            <span
              onClick={() => {
                setModalOpen(true);
              }}
              className="font-arabic-regular"
            >
              تعلم المزيد
            </span>
          </p>
        ) : (
          ""
        )}
        <h1
          className={`text-light pt-5 start-text mx-2 font-arabic-semibold ${mohafazaCasa ===
            true && "d-none"}`}
        >
          {noRegion === false
            ? `منزلك في ${regionName}, ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`
            : `منزلك في ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`}
        </h1>
        <h1
          className={`text-light pt-5 start-text mx-2 font-arabic-semibold ${mohafazaCasa ===
            false && "d-none"}`}
        >
          {mohafazaCasa === true
            ? `منزلك في ${casaName}${roomsText}${peopleText}.`
            : ""}
        </h1>
        <h3 className="pt-4 text-light font-arabic-regular">
          بناءً على مدخلاتك، سوف تحتاج إلى:
        </h3>
        <div className="d-flex row justify-content-evenly m-auto mx-4">
          {results.map(({ image, number, unit, title, text }, index) => {
            return (
              <ResultCardAr
                image={image}
                title={title}
                unit={unit}
                number={number}
                key={index}
                text={text}
              />
            );
          })}
        </div>

        {runningCurrent ? (
          <div className="text-center mx-4 mt-4">
            <p className="result-page-estimate-text mb-2 font-arabic-semibold">
              تم تحديد التقديرات أدناه على أساس يوم واحد من استقلالية البطارية *
            </p>
            <div className="result-page-supply ">
              <p className="pb-2 mb-0 font-arabic-regular">
                وبالتالي يمكن لنظامك التقريبي توفير
              </p>
              <input
                type="range"
                id="supply"
                min="0"
                max={maxAmpere}
                value={value}
                className="pt-2 left-direction"
                onChange={(e) => {
                  calculate(e);
                  setDurationValue(e.target.value);
                }}
                style={{
                  background:
                    "linear-gradient(to right, #4B4BFD 0%, #4B4BFD " +
                    newValue +
                    "%, rgba(75, 75, 253, 0.2) " +
                    newValue +
                    "%, rgba(75, 75, 253, 0.2) 100%)",
                }}
              />
              <div
                htmlFor="supply"
                className="pt-2 text-end result-page-hours me-3 font-arabic-regular"
              >
                {value}أمبير
              </div>

              <div className="d-flex justify-content-between align-items-center px-3">
                <p className="mb-0 font-arabic-regular">على مدى:</p>
                <div className="result-page-hours font-arabic-semibold">
                  {!duration || duration === Infinity
                    ? ""
                    : `${Math.round(duration)} ساعات`}
                </div>
              </div>
            </div>
            <p className="power-autonomy-text pt-2 mx-1 font-arabic-regular">
              * استقلالية البطارية = الوقت الذي يمكن خلاله تلبية الاحتياجات من
              الطاقة الكهربائية عن طريق استخدام البطارية وحدها، دون أي تزويد من
              الطاقة الشمسية، بدءاً بالطبع من البطارية "المشحونة بالكامل".
            </p>
          </div>
        ) : (
          ""
        )}

        {modalDefaultOpen === true && hideButtons === false ? (
          <div>
            {!simpleApp ? (
              <Link to="/tips-ar" state={{ tips: tips, pvSize: pvSize }}>
                <button className="tips m-auto mt-3 py-3 text-center font-arabic-semibold">
                  تحقق من جميع نصائحك هنا.
                </button>
              </Link>
            ) : (
              ""
            )}
            <button
              type="button"
              className="font-arabic-semibold ms-lg-2 ms-md-2 ms-sm-2 download-btn mb-2 mt-2 text-center me-lg-2 me-md-2"
              onClick={screenshot}
            >
              أنزل نتائجي <img src={download} alt="" className="ps-2" />
            </button>
            <button
              type="button"
              className="share-btn "
              onClick={() => {
                setModalDefaultOpen(false);
              }}
            >
              <img src={share} alt="" width="10" className="share-svg" />
              <p className="power-autonomy-text pt-2 pe-2 font-arabic-regular">
                تبادل نتائجي
              </p>
            </button>
          </div>
        ) : (
          <div className={`d-block ${modalDefaultOpen === true && "d-none"}`}>
            <div className="modal-dialog mx-4">
              <div className="modal-body mb-3 p-0 mx-4 ">
                <div className="pt-3 pe-3 float-start mb-4">
                  <button
                    type="button"
                    className="btn-close shadow-none"
                    onClick={() => {
                      setModalDefaultOpen(true);
                    }}
                  ></button>
                </div>
                <h3 className="lh-sm text-end location-text pt-4 mb-1 direction font-arabic-regular">
                  لقد استخدمت للتو هذه الآلة الحاسبة للطاقة الشمسية من برنامج
                  الأمم المتحدة الإنمائي للحصول على تقدير لإدارة الطاقة في
                  منزلي، جربها هنا:
                  <br />
                  <br />
                  <p className="link">
                    https://solarcalculator.energyhub-lb.com/#/results-ar/{linkID}
                  </p>
                </h3>
              </div>
              <div className="modal-footer my-4 border-0 text-start mx-4">
                <button onClick={copy}>Copy message</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResultsAr;
