import React, { useState, useEffect } from "react";
import sunny from "../assets/images/sunny.svg";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function FeedbackSolar() {
  const location = useLocation();
  const [kwh, setKwh] = useState("");
  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");

  const [energy_sources, setEnergy_sources] = useState();
  const [results, setResults] = useState("");

  //
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (location.state) {
      setAge(location.state.age);
      setPeople(location.state.people);
      setRooms(location.state.rooms);
      setGender(location.state.gender);
      setEnergy_manager(location.state.energy_manager);
      setManager(location.state.manager);
      setItems(location.state.items);
      setAreaPicked(location.state.areaPicked);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state.items) {
      setEnergy_sources(JSON.stringify(location.state.items));
    }
  }, [location.state.items]);

  useEffect(() => {
    if (energy_sources) {
      axios
        .post(
          `https://apisolarcalculator.energyhub-lb.com/api.php?action=power_calculation&energy_sources=${energy_sources}`
        )
        .then((res) => {
          setResults(JSON.parse(res.data.data));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [energy_sources]);

  // useEffect(() => {
  //   if (location.state) {
  //     setAreaPicked(location.state.areaPicked);
  //     setMohafazaName(location.state.mohafazaName);
  //     setCasaName(location.state.casaName);
  //     setRegionName(location.state.regionName);
  //   }
  // }, [location.state]);

  // useEffect(() => {
  //   if (areaPicked === "" && !location.state) {
  //     navigate("/");
  //   }
  // }, [areaPicked]);
  const formatNumber = (number) => {
    const numberString = number.toString();
    const [integerPart] = numberString.split(".");
    return integerPart;
  };

  useEffect(() => {
    if (results.xxx && results.yyy && results.zzz) {
      setKwh(formatNumber(results.xxx));
      setUsd(formatNumber(results.yyy));
      setSavings(formatNumber(results.zzz));
    }
  }, [results]);

  return (
    <div>
      <div className="feedback-page feedback-solar-page mx-lg-auto">
        <div className="feedback-image-height">
          <img src={sunny} alt="" className="mt-lg-4 mt-md-4 mt-2" />
        </div>
        <h3 className="mt-lg-5 mt-md-5 mt-4 location-text mb-2">
          Your Installed solar system is
        </h3>
        <h3>Generating</h3>
        <h2>{kwh} KWH/year</h2>
        <h3>Saving</h3>
        <h2>{usd} USD/year</h2>
        <h3>Reducing</h3>
        <h2>{savings} Kg CO2/year.</h2>

        <Link
          to="/solar-estimation"
          state={{
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,
            areaPicked: areaPicked,

            manager: manager,
            energy_manager: energy_manager,
            items: items,
            gender: gender,
            rooms: rooms,
            people: people,
            age: age,
            kwh: kwh,
            usd: usd,
            savings: savings,
          }}
        >
          <button className="next-btn rounded-pill" type="button">
            I want to calculate my efficiency
          </button>
        </Link>

        <div className="d-flex padding-bottom justify-content-center position-sticky footer-style">
          <Link to={-1}>
            <div className={`active-feedback box`}></div>
          </Link>
          <div className={`inactive-feedback box`}></div>
          <div className={`inactive-feedback box`}></div>
          <div className={`inactive-feedback box`}></div>
          <div className={`inactive-feedback box`}></div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackSolar;
