import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import tip from "../assets/GIF/tip.gif";

function TipsAr() {
  const navigate = useNavigate();
  const location = useLocation();
  //
  const [aaa, setAaa] = useState("");
  const [pvSize, setPvSize] = useState("");

  const [battery, setBattery] = useState("");
  const [kwh, setKwh] = useState("");
  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");
  const [items, setItems] = useState([]);
  const [areaPicked, setAreaPicked] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [simpleApp, setSimpleApp] = useState();
  //
  const [tips, setTips] = useState([]);
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");

  useEffect(() => {
    if (location.state) {
      //
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setEnergy_manager(location.state.energy_manager);
      setAppliancesUpdates(location.state.appliancesUpdates);
      setAppliance(location.state.appliance);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setSimpleApp(location.state.simpleApp);
      setPvSize(location.state.pvSize);

      setKwh(location.state.kwh);
      setAaa(location.state.aaa);
      setBattery(location.state.battery);
      setTips(location.state.tips);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setItems(location.state.items);
    }
  }, [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="appliances-feedback-page px-3 mx-lg-auto ">
        <div className="appliances-feedback-page-header">
          <img src={tip} alt="" className="mt-2 pt-2" width="250" />
          <h1 className="location-text mt-4 mx-3 direction font-arabic-semibold">
            قبل المتابعة، سيقدم لك مهندسونا هذه النصيحة.
          </h1>
        </div>
        <div>
          {tips.map((tip, index) => {
            return (
              <div className="background-white pb-0 mx-4" key={index}>
                <p className="py-4 mx-4 font-arabic-regular">{tip}</p>
              </div>
            );
          })}
          <p className="appliances-feedback-tips-text pb-4 font-arabic-regular direction">
            جميع هذه النصائح ستكون متاحة على صفحة النتائج. اضغط على "التالي"
            للتحقق منها لاحقاً.
          </p>
        </div>
        <div className="appliances-feedback-page-background position-sticky">
          {pvSize ? (
            <button
              className="appliance-feedback-btn mt-2 rounded-pill mb-3 font-arabic-regular"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              رجوع
            </button>
          ) : (
            <>
              <Link
                to="/calculating-ar"
                state={{
                  rooms: rooms,
                  people: people,
                  age: age,
                  gender: gender,
                  manager: manager,
                  energy_manager: energy_manager,
                  appliancesUpdates: appliancesUpdates,
                  appliance: appliance,
                  mohafazaName: mohafazaName,
                  casaName: casaName,
                  regionName: regionName,
                  areaPicked: areaPicked,
                  tips: tips,
                  simpleApp: simpleApp,

                  items: items,
                  kwh: kwh,
                  usd: usd,
                  aaa: aaa,
                  savings: savings,
                  battery: battery,
                }}
              >
                <button
                  className="appliance-feedback-btn mt-2 rounded-pill mb-3 font-arabic-regular"
                  type="button"
                >
                  التالي
                </button>
              </Link>

              <div className="d-flex padding-bottom justify-content-center direction">
                <div
                  className={`active-feedback box`}
                  onClick={() => {
                    navigate("/energy-manager-ar");
                  }}
                ></div>
                <div
                  className={`active-feedback box`}
                  onClick={() => {
                    navigate("/appliances-ar");
                  }}
                ></div>
                <div
                  className={`active-feedback box`}
                  onClick={() => {
                    navigate("/batteries-ar");
                  }}
                ></div>
                <div className={`inactive-feedback box`}></div>
                <div className={`inactive-feedback box`}></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TipsAr;
