import React, { useEffect } from "react";

function CategoriesAr({
  image,
  title,
  index,
  indexNew,
  setIndexNew,
  id,
  setCategoryId,
}) {
  function getActiveClassTitle(index) {
    return index === indexNew
      ? "title-appliance pt-1  opacity-100 font-arabic-regular"
      : "title-appliance pt-1 font-arabic-regular";
  }

  useEffect(() => {
    if (index === 0) {
      setIndexNew(0);
    }
  }, []);

  useEffect(() => {
    if (indexNew === 1) {
      setCategoryId(1);
    }
    if (indexNew === 2) {
      setCategoryId(4);
    }
    if (indexNew === 3) {
      setCategoryId(2);
    }
    if (indexNew === 4) {
      setCategoryId(6);
    }
    if (indexNew === 5) {
      setCategoryId(3);
    }
    if (indexNew === 6) {
      setCategoryId(7);
    }
  }, [indexNew]);
  useEffect(() => {
    if (indexNew === 0) {
      setCategoryId(5);
    }
  }, [indexNew]);

  return (
    <div
      className={`me-2 pointer opacity-50 ${index === indexNew &&
        "opacity-100"}`}
      onClick={() => {
        setCategoryId(id);
      }}
    >
      <div className="bg-white category-border">{image}</div>
      <div className={getActiveClassTitle(index)}>{title}</div>
    </div>
  );
}

export default CategoriesAr;
