import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import HomePage from './components/HomePage';
import Location from './components/Location';
import Feedback from './components/Feedback';
import FeedbackSolar from './components/FeedbackSolar';
import FeedbackSources from './components/FeedbackSources';
import EnergyConsumption from './components/EnergyConsumption';
import EnergySources from './components/EnergySources';
import HomeDetails from './components/HomeDetails';
import Appliances from './components/Appliances';
import AppliancesFeedback from './components/AppliancesFeedback';
import Calculating from './components/Calculating';
import Results from './components/Results';
import EnergyManager from './components/EnergyManager';
import Batteries from './components/Batteries';
import AppliancesSimplified from './components/AppliancesSimplified';
import Tips from './components/Tips';
import ResultId from './components/ResultId';
import SolarEstimation from './components/SolarEstimation';

// 
import LocationAr from './arabic components/LocationAr';
import FeedbackAr from './arabic components/FeedbackAr';
import FeedbackSolarAr from './arabic components/FeedbackSolarAr';
import FeedbackSourcesAr from './arabic components/FeedbackSourcesAr';
import HomeDetailsAr from './arabic components/HomeDetailsAr';
import EnergyManagerAr from './arabic components/EnergyManagerAr';
import AppliancesAr from './arabic components/AppliancesAr';
import BatteriesAr from './arabic components/BatteriesAr';
import CalculatingAr from './arabic components/CalculatingAr';
import AppliancesFeedbackAr from './arabic components/AppliancesFeedbackAr';
import ResultsAr from './arabic components/ResultsAr';
import TipsAr from './arabic components/TipsAr';
import ResultIdAr from './arabic components/ResultIdAr';
import AppliancesSimplifiedAr from './arabic components/AppliancesSimplifiedAr';
import SolarEstimationAr from './arabic components/SolarEstimationAr';
import EnergyConsumptionAr from './arabic components/EnergyConsumptionAr';
import EnergySourcesAr from './arabic components/EnergySourcesAr';




function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/location" element={<Location />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback-solar" element={<FeedbackSolar />} />
        <Route path="/feedback-sources" element={<FeedbackSources />} />
        <Route path="/energy-sources" element={<EnergySources />} />
        <Route path="/home-details" element={<HomeDetails />} />
        <Route path="/energy-manager" element={<EnergyManager />} />
        <Route path="/energy-consumption" element={<EnergyConsumption />} />
        <Route path="/solar-estimation" element={<SolarEstimation />} />
        <Route path="/appliances-simplified" element={<AppliancesSimplified />} />
        <Route path="/appliances" element={<Appliances />} />
        <Route path="/appliances-feedback" element={<AppliancesFeedback />} />
        <Route path="/batteries" element={<Batteries />} />
        <Route path="/calculating" element={<Calculating />} />
        <Route path="/results" element={<Results />} />
        <Route path="/results/:id" element={<ResultId />} />
        <Route path="/tips" element={<Tips />} />
        {/* arabic */}
        <Route path="/location-ar" element={<LocationAr />} />
        <Route path="/feedback-ar" element={<FeedbackAr />} />
         <Route path="/home-details-ar" element={<HomeDetailsAr />} />
         <Route path="/energy-manager-ar" element={<EnergyManagerAr />} />
        <Route path="/appliances-ar" element={<AppliancesAr />} />
        <Route path="/appliances-feedback-ar" element={<AppliancesFeedbackAr />} />
        <Route path="/batteries-ar" element={<BatteriesAr />} />
        <Route path="/calculating-ar" element={<CalculatingAr />} />
        <Route path="/results-ar" element={<ResultsAr />} />
        <Route path="/results-ar/:id" element={<ResultIdAr />} />
        <Route path="/tips-ar" element={<TipsAr />} />
        <Route path="/energy-sources-ar" element={<EnergySourcesAr />} />
        <Route path="/energy-consumption-ar" element={<EnergyConsumptionAr />} />
        <Route path="/feedback-solar-ar" element={<FeedbackSolarAr />} />
        <Route path="/feedback-sources-ar" element={<FeedbackSourcesAr />} />
        <Route path="/appliances-simplified-ar" element={<AppliancesSimplifiedAr />} />
        <Route path="/solar-estimation-ar" element={<SolarEstimationAr />} />


      </Routes>
    </div>
  );
}

export default App;
