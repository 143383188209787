import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import lithium from "../assets/images/lithium.svg";
import lead from "../assets/images/lead-acid.svg";
import AGM from "../assets/images/AGM.svg";
import X from "../assets/images/X.svg";

function Batteries() {
  const location = useLocation();
  const navigate = useNavigate();
  const [battery, setBattery] = useState("");
  const [batteryChanged, setBatteryChanged] = useState(false);
  const [lithiumBatteries, setLithiumBatteries] = useState(false);
  const [leadAcidBatteries, setLeadAcidBatteries] = useState(false);
  const [agmBatteries, setAgmBatteries] = useState(false);
  const [lithiumBattInfo, setLithiumBattInfo] = useState(false);
  const [leadBattInfo, setLeadBattInfo] = useState(false);
  const [aGMBattInfo, setAGMBattInfo] = useState(false);
  const [kwh, setKwh] = useState("");
  const [aaa, setAaa] = useState("");

  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");
  const [items, setItems] = useState([]);
  const [tips, setTips] = useState([]);
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [mohafazaName, setMohafazaName] = useState("");
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [simpleApp, setSimpleApp] = useState();

  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setEnergy_manager(location.state.energy_manager);
      setAppliancesUpdates(location.state.appliancesUpdates);
      setAppliance(location.state.appliance);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setSimpleApp(location.state.simpleApp);

      setKwh(location.state.kwh);
      setTips(location.state.tips);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setItems(location.state.items);
      setAaa(location.state.aaa);
    }
  }, [location.state]);

  useEffect(() => {
    console.log(tips);
  }, [tips]);

  useEffect(() => {
    if (batteryChanged && tips.length === 0) {
      navigate("/calculating", {
        state: {
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
          simpleApp: simpleApp,

          items: items,
          kwh: kwh,
          aaa: aaa,
          usd: usd,
          savings: savings,
          battery: battery,
        },
      });
    } else if (batteryChanged && tips.length !== 0) {
      navigate("/tips", {
        state: {
          rooms: rooms,
          people: people,
          age: age,
          gender: gender,
          manager: manager,
          energy_manager: energy_manager,
          appliancesUpdates: appliancesUpdates,
          appliance: appliance,
          mohafazaName: mohafazaName,
          casaName: casaName,
          regionName: regionName,
          areaPicked: areaPicked,
          tips: tips,
          simpleApp: simpleApp,

          items: items,
          kwh: kwh,
          aaa: aaa,
          usd: usd,
          savings: savings,
          battery: battery,
        },
      });
    }
  }, [batteryChanged]);

  return (
    <div>
      <div className="batteries-page mx-lg-auto">
        <h3 className="mb-0">Almost there!</h3>
        <h3>
          Before you get your personalised estimate, what battery type would you
          choose?
        </h3>
        <div className="battery-container">
          {lithiumBattInfo === false ? (
            <>
              <div>
                <div
                  className={`mt-4 battery-type ${lithiumBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setLithiumBatteries(true);
                    setBattery("lithium-ion");
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={lithium} alt="" className="me-4" />
                    <h1 className="mb-0">Lithium-ion Batteries</h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setLithiumBattInfo(true);
                  }}
                  className="learn-more-text"
                >
                  Learn More about specs of Lithium Batteries
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setLithiumBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4>Lithium Batteries</h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title">Lifespan</p>
                  <p className="batt-properties">Up to 10 years</p>
                </div>
                <div>
                  <p className="batt-info-title">Maintenance</p>
                  <p className="batt-properties">Free</p>
                </div>
                <div>
                  <p className="batt-info-title">Price</p>
                  <p className="batt-properties">Up to 4x</p>
                  <p className="comparison-text">
                    Compared to lead-acid or AGM
                  </p>
                </div>
                <div>
                  <p className="batt-info-title">Weight</p>
                  <p className="batt-properties"> 4x Lighter</p>
                  <p className="comparison-text">Than lead-acid or AGM</p>
                </div>
              </div>
            </div>
          )}

          {leadBattInfo === false ? (
            <>
              <div>
                <div
                  className={`battery-type ${leadAcidBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setBattery("lead_acid");
                    setLeadAcidBatteries(true);
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={lead} alt="" className="me-4" />
                    <h1 className="d-inline-block mb-0">Lead Acid Batteries</h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setLeadBattInfo(true);
                  }}
                  className="learn-more-text"
                >
                  Learn More about specs of Lead Acid Batteries
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={(e) => {
                  setLeadBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4>Lead Acid Batteries</h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title">Lifespan</p>
                  <p className="batt-properties">3-5 years</p>
                </div>
                <div>
                  <p className="batt-info-title">Maintenance</p>
                  <p className="batt-properties">Required</p>
                </div>
                <div>
                  <p className="batt-info-title">Price</p>
                  <p className="batt-properties">Cheap</p>
                  <p className="comparison-text">
                    Compared to lithium batteries
                  </p>
                </div>
                <div>
                  <p className="batt-info-title">Space</p>
                  <p className="batt-properties">4x</p>
                  <p className="comparison-text">
                    of its equivalent in lithium batteries
                  </p>
                </div>
                <div>
                  <p className="batt-info-title">Over-charging</p>
                  <p className="batt-properties">Tolerates</p>
                </div>
                <div>
                  <p className="batt-info-title">Self Discharging</p>
                  <p className="batt-properties">High rate</p>
                </div>
              </div>
            </div>
          )}

          {aGMBattInfo === false ? (
            <>
              <div>
                <div
                  className={`battery-type ${agmBatteries === true &&
                    "border-selected-battery"}`}
                  onClick={(e) => {
                    setBattery("agm");
                    setAgmBatteries(true);
                    setBatteryChanged(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={AGM} alt="" className="me-4" />
                    <h1 className="d-inline-block mb-0">AGM Batteries</h1>
                  </div>
                </div>
                <p
                  onClick={() => {
                    setAGMBattInfo(true);
                  }}
                  className="learn-more-text"
                >
                  Learn More about specs of AGM Batteries
                </p>
              </div>
            </>
          ) : (
            <div className="batt_info_container mt-4">
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setAGMBattInfo(false);
                }}
              >
                <img src={X} alt="close-logo" />
              </button>
              <h4>AGM Batteries</h4>
              <div className="batt_info">
                <div>
                  <p className="batt-info-title">Lifespan</p>
                  <p className="batt-properties">3-5 years</p>
                </div>
                <div>
                  <p className="batt-info-title">Maintenance</p>
                  <p className="batt-properties">Free</p>
                </div>
                <div>
                  <p className="batt-info-title">Price</p>
                  <p className="batt-properties">Cheap</p>
                  <p className="comparison-text">
                    Compared to lithium batteries
                  </p>
                </div>
                <div>
                  <p className="batt-info-title">Space</p>
                  <p className="batt-properties">4x</p>
                  <p className="comparison-text">
                    of its equivalent in lithium batteries
                  </p>
                </div>
                <div>
                  <p className="batt-info-title">Over-charging</p>
                  <p className="batt-properties">Sensitive</p>
                </div>
                <div>
                  <p className="batt-info-title">Self Discharging</p>
                  <p className="batt-properties">Low rate</p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex padding-bottom justify-content-center position-sticky background-box">
          <div
            className={`active box`}
            onClick={() => {
              navigate(-3);
            }}
          ></div>
          <div
            className={`active box`}
            onClick={() => {
              navigate(-2);
            }}
          ></div>
          <div
            className={`active box`}
            onClick={() => {
              navigate(-1);
            }}
          ></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
        </div>
      </div>
    </div>
  );
}

export default Batteries;
