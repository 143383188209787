import React, { useState, useEffect } from "react";
import eyes from "../assets/images/eyes.svg";
import electricity from "../assets/images/electricity.svg";
import solar from "../assets/images/solar_system.svg";
import { Link, useLocation } from "react-router-dom";

function EnergyConsumption() {
  const location = useLocation();

  const [newData, setNewData] = useState("");

  const [firstConsupmtion, setFirstConsupmtion] = useState("");
  const [firstCost, setFirstCost] = useState("");
  const [secondConsumption, setSecondConsumption] = useState("");
  const [secondCost, setSecondCost] = useState("");
  const [thirdConsumption, setThirdConsumption] = useState("");
  const [thirdCost, setThirdCost] = useState("");

  const [panelsNumbers, setPanelsNumbers] = useState("");
  const [panelsSize, setPanelsSize] = useState("");

  const [dataEntered, setDataEntered] = useState(false);
  const [solarPicked, setSolarPicked] = useState(false);

  const [electricityNewPicked, setElectricityNewPicked] = useState(false);
  const [generatorNewPicked, setGeneratorNewPicked] = useState(false);
  const [systemNewPicked, setSystemNewPicked] = useState(false);
  const [privateGeneratorNewPicked, setPrivateGeneratorNewPicked] = useState(
    false
  );

  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");

  //

  useEffect(() => {
    setRegionName(location.state.regionName);
    setCasaName(location.state.casaName);
    setMohafazaName(location.state.mohafazaName);
    setAreaPicked(location.state.areaPicked);
  }, [
    location.state.casaName,
    location.state.areaPicked,
    location.state.mohafazaName,
    location.state.regionName,
  ]);

  useEffect(() => {
    setElectricityNewPicked(location.state.electricityPicked);
    setGeneratorNewPicked(location.state.generatorPicked);
    setSystemNewPicked(location.state.systemPicked);
    setPrivateGeneratorNewPicked(location.state.privateGeneratorPicked);
  }, [location.state]);

  useEffect(() => {
    if (location.state.systemPicked === true) {
      setSolarPicked(true);
    } else {
      setSolarPicked(false);
    }
  }, [location.state.systemPicked]);

  const [data, setData] = useState({
    "1": {
      name: "Electricity of Lebanon",
      avg_consumption: 0,
      avg_cost: 0,
      picked: false,
    },
    "2": {
      name: "Generator Subscription",
      avg_consumption: 0,
      avg_cost: 0,
      picked: false,
    },
    "3": {
      name: "Solar System",
      panels_count: 0,
      panels_size: 0,
      picked: false,
    },
    "4": {
      name: "Private Generator",
      avg_consumption: 0,
      avg_cost: 0,
      picked: false,
    },
  });

  const modifyFirstDataCons = (consumption) => {
    setData((prevData) => ({
      ...prevData,
      "1": {
        ...prevData["1"],
        avg_consumption: consumption,
      },
    }));
  };

  const modifyFirstDataCost = (cost) => {
    setData((prevData) => ({
      ...prevData,
      "1": {
        ...prevData["1"],
        avg_cost: cost,
      },
    }));
  };
  const modifySecondDataCons = (consumption) => {
    setData((prevData) => ({
      ...prevData,
      "2": {
        ...prevData["2"],
        avg_consumption: consumption,
      },
    }));
  };
  const modifySecondDataCost = (cost) => {
    setData((prevData) => ({
      ...prevData,
      "2": {
        ...prevData["2"],
        avg_cost: cost,
      },
    }));
  };
  const modifyPanelCount = (count) => {
    setData((prevData) => ({
      ...prevData,
      "3": {
        ...prevData["3"],
        panels_count: count,
      },
    }));
  };
  const modifyPanelSize = (size) => {
    setData((prevData) => ({
      ...prevData,
      "3": {
        ...prevData["3"],
        panels_size: size,
      },
    }));
  };
  const modifyFourthDataCons = (consumption) => {
    setData((prevData) => ({
      ...prevData,
      "4": {
        ...prevData["4"],
        avg_consumption: consumption,
      },
    }));
  };
  const modifyFourthDataCost = (cost) => {
    setData((prevData) => ({
      ...prevData,
      "4": {
        ...prevData["4"],
        avg_cost: cost,
      },
    }));
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (electricityNewPicked) {
      setData((prevData) => ({
        ...prevData,
        "1": {
          ...prevData["1"],
          picked: true,
        },
      }));
    }
    if (generatorNewPicked) {
      setData((prevData) => ({
        ...prevData,
        "2": {
          ...prevData["2"],
          picked: true,
        },
      }));
    }
    if (systemNewPicked) {
      setData((prevData) => ({
        ...prevData,
        "3": {
          ...prevData["3"],
          picked: true,
        },
      }));
    }
    if (privateGeneratorNewPicked) {
      setData((prevData) => ({
        ...prevData,
        "4": {
          ...prevData["4"],
          picked: true,
        },
      }));
    }
  }, [
    electricityNewPicked,
    generatorNewPicked,
    systemNewPicked,
    privateGeneratorNewPicked,
  ]);

  useEffect(() => {
    const entries = Object.entries(data);
    const pickedItems = entries.filter(([id, item]) => item.picked);
    const allPickedItemsValid = pickedItems.every(([id, item]) => {
      if (id == "3") {
        return item.panels_count && item.panels_size;
      } else if (id == "1") {
        return item.avg_consumption;
      } else {
        return item.avg_cost && item.avg_consumption;
      }
    });

    if (allPickedItemsValid) {
      setDataEntered(true);
    } else {
      setDataEntered(false);
    }
  }, [data]);

  useEffect(() => {
    const updatedData = Object.keys(data).reduce((acc, key) => {
      const { picked, ...rest } = data[key];
      acc[key] = rest;
      return acc;
    }, {});

    setNewData(updatedData);
  }, [data]);

  useEffect(() => {
    console.log(newData);
  }, [newData]);

  return (
    <div>
      <div className="location-page mx-lg-auto energy-source-page">
        <img
          src={eyes}
          alt=""
          className="mt-lg-5 mt-md-5 mt-2 energy-source-image"
        />
        <h1 className="mt-lg-4 mt-md-4 mb-0">
          Fill in any of these fields that you know of so we can learn more
          about your energy profile
        </h1>

        {location.state.electricityPicked === true ? (
          <>
            <div className="energy-item">
              <div className="energy-source-header">
                <h3>Electricity of Lebanon</h3>
                <img src={electricity} alt="electricity" />
              </div>
              <div className="source-details-grid">
                <div>
                  <h4>
                    Avg. Consumption <br />
                    <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${firstConsupmtion &&
                        `border-selected-input`}`}
                      onChange={(e) => {
                        modifyFirstDataCons(e.target.value);
                        setFirstConsupmtion(e.target.value);
                      }}
                    />
                    <p>kWh</p>
                  </div>
                </div>
                <div>
                  <h4>
                    Avg. Cost
                    <br /> <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${firstCost && `border-selected-input`}`}
                      onChange={(e) => {
                        modifyFirstDataCost(e.target.value);
                        setFirstCost(e.target.value);
                      }}
                    />
                    <p>$</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {location.state.generatorPicked === true ? (
          <>
            <div className="energy-item">
              <div className="energy-source-header">
                <h3>Generator Subscription</h3>
                <img src={electricity} alt="electricity" />
              </div>
              <div className="source-details-grid">
                <div>
                  <h4>
                    Avg. Consumption <br />
                    <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${secondConsumption &&
                        `border-selected-input`}`}
                      onChange={(e) => {
                        modifySecondDataCons(e.target.value);
                        setSecondConsumption(e.target.value);
                      }}
                    />
                    <p>kWh</p>
                  </div>
                </div>
                <div>
                  <h4>
                    Avg. Cost
                    <br /> <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${secondCost && `border-selected-input`}`}
                      onChange={(e) => {
                        modifySecondDataCost(e.target.value);
                        setSecondCost(e.target.value);
                      }}
                    />
                    <p>$</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {location.state.systemPicked === true ? (
          <>
            <div className="energy-item">
              <div className="energy-source-header">
                <h3>Solar System</h3>
                <img src={solar} alt="solar" />
              </div>
              <div className="source-details-grid">
                <div>
                  <h4 className="mb-2">Number of panels</h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${panelsNumbers && `border-selected-input`}`}
                      onChange={(e) => {
                        modifyPanelCount(e.target.value);
                        setPanelsNumbers(e.target.value);
                      }}
                    />
                    <p>Panels</p>
                  </div>
                </div>
                <div>
                  <h4 className="mb-2">Size of panels</h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${panelsSize && `border-selected-input`}`}
                      onChange={(e) => {
                        modifyPanelSize(e.target.value);
                        setPanelsSize(e.target.value);
                      }}
                    />
                    <p>
                      Watt
                      <br /> Peak
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {location.state.privateGeneratorPicked === true ? (
          <>
            <div className="energy-item">
              <div className="energy-source-header">
                <h3>Private Generator</h3>
                <img src={electricity} alt="electricity" />
              </div>
              <div className="source-details-grid">
                <div>
                  <h4>
                    Avg. Consumption <br />
                    <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${thirdConsumption &&
                        `border-selected-input`}`}
                      onChange={(e) => {
                        modifyFourthDataCons(e.target.value);
                        setThirdConsumption(e.target.value);
                      }}
                    />
                    <p>kWh</p>
                  </div>
                </div>
                <div>
                  <h4>
                    Avg. Cost
                    <br /> <i>/month</i>
                  </h4>
                  <div className="input-field">
                    <input
                      type="number"
                      className={`${thirdCost && `border-selected-input`}`}
                      onChange={(e) => {
                        modifyFourthDataCost(e.target.value);
                        setThirdCost(e.target.value);
                      }}
                    />
                    <p>$</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <Link
          to="/home-details"
          state={{
            areaPicked: areaPicked,
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,

            solarPicked: solarPicked,
            items: newData,
            electricityNewPicked: electricityNewPicked,
            generatorNewPicked: generatorNewPicked,
            systemNewPicked: systemNewPicked,
            privateGeneratorNewPicked: privateGeneratorNewPicked,
          }}
        >
          <button
            className="next-btn mt-4 rounded-pill mb-lg-5 mb-md-5 mb-2"
            type="button"
            disabled={!dataEntered}
          >
            Next
          </button>
        </Link>

        <div className=" position-sticky footer-style pb-4">
          <p className="text-lg-center text-md-center text-sm-center text-start mb-2 mt-lg-4 mt-md-4 mt-2">
            Try to answer all the questions to get your 5 stars energy
            management report
          </p>

          <div className="d-flex  justify-content-center ">
            <div className={`active box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
            <div className={`inactive box`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergyConsumption;
