import React, { useState, useEffect } from "react";
import ResultCard from "./shared/ResultCard";
import battery from "../assets/images/battery.svg";
import system from "../assets/images/system.svg";
import roof from "../assets/images/roof.svg";
import space from "../assets/images/space.svg";
import inverter from "../assets/images/inverter.svg";
import amps from "../assets/images/amps.svg";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import axios from "axios";

function Results() {
  const { id } = useParams();
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [runningCurrent, setRunningCurrent] = useState("");
  const [value, setValue] = useState(runningCurrent);
  const [noRegion, setNoRegion] = useState(false);
  const [mohafazaCasa, setMohafazaCasa] = useState(false);
  const [newValue, setNewValue] = useState(((value - 0) / (300 - 0)) * 100);
  const [data, setData] = useState("");
  const [batteryBankSize, setBatteryBankSize] = useState("");
  const [maxAmp, setMaxAmp] = useState("");
  const [minInverterSize, setMinInverterSize] = useState("");
  const [minLandRequired, setMinLandRequired] = useState("");
  const [pvSize, setPvSize] = useState("");
  const [spaceVolumeNeeded, setSpaceVolumeNeeded] = useState("");
  const [maxAmpere, setMaxAmpere] = useState("");
  const [linkID, setLinkID] = useState("");
  const [dod, setDod] = useState("");
  const [duration, setDuration] = useState("");
  const [hideButtons, setHideButtons] = useState(false);
  const [durationValue, setDurationValue] = useState("");
  const [peopleText, setPeopleText] = useState("");
  const [roomsText, setRoomsText] = useState("");

  useEffect(() => {
    setValue(runningCurrent);
  }, [runningCurrent]);
  useEffect(() => {
    setMaxAmpere(maxAmp);
  }, [maxAmp]);

  useEffect(() => {
    if (data) {
      setBatteryBankSize(data.battery_bank_size);
      setMaxAmp(data.max_amp);
      setMinInverterSize(data.min_inverter_size);
      setMinLandRequired(data.min_land_required);
      setPvSize(data.pv_size);
      setSpaceVolumeNeeded(data.space_volume_needed);
      setRunningCurrent(data.running_current);
      setDod(data.dod);
      setLinkID(data.results_link);
      setRooms(data.location_rooms);
      setPeople(data.location_people);
      setMohafazaName(data.region_breadcrumbs.mouhafaza_name);
      setCasaName(data.region_breadcrumbs.caza_name);
      setRegionName(data.region_breadcrumbs.region);
    }
  }, [data]);

  useEffect(() => {
    axios
      .get(
        `https://apisolarcalculator.energyhub-lb.com/api.php?action=results&id=${id}`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const results = [
    {
      number: <p className="mb-0 card-number">{pvSize}</p>,
      unit: "kWp",
      image: <img src={system} alt="" className="pt-3 result-card-image" />,
      title: "PV system size",
      text:
        "Maximum Power that solar panels can generate according to the number of panels installed",
    },
    {
      number: <p className="mb-0 card-number">{batteryBankSize}</p>,
      unit: "kWh",
      image: <img src={battery} alt="" className="pt-3 result-card-image" />,
      title: "Lithium Battery Bank",
      text:
        "A type of rechargeable battery which uses the reversible reduction of lithium ions to store energy",
    },

    {
      number: <p className="mb-0 card-number">{minLandRequired}</p>,
      unit: "m2",
      image: <img src={roof} alt="" className="pt-3 result-card-image" />,
      title: "Min. roof or land area required",
      text:
        "Area that will be covered by all solar panels including inclination to avoid shading",
    },
    // {
    //   number: <p className="mb-0 card-number">{spaceVolumeNeeded}</p>,
    //   unit: "m3",
    //   image: <img src={space} alt="" className="pt-3 result-card-image" />,
    //   title: "Approximate space volume needed for batteries",
    //   text:
    //     "The space needed to install batteries shall be well ventilated and away from water (rain) and sun",
    // },
    {
      number: <p className="mb-0 card-number">{minInverterSize}</p>,
      unit: "kW",
      image: <img src={inverter} alt="" className="pt-3 result-card-image" />,
      title: "Minimum Inverter Size",
      text: "The maximum power that can be consumed before tripping",
    },
    {
      number: <p className="mb-0 card-number">{maxAmp}</p>,
      unit: "A",
      image: <img src={amps} alt="" className="pt-3 result-card-image" />,
      title: "Maximum Amps delivered from Inverter",
      text: "The maximum current that can be withdrawn before tripping",
    },
  ];
  var min = 0;
  var max = maxAmpere;
  function calculate(e) {
    setValue(e.target.value);
  }
  useEffect(() => {
    setNewValue(((value - min) / (max - min)) * 100);
  }, [value]);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `I just used this solar calculator from UNDP to get an energy management estimate for my home, try it here: https://solarcalculator.energyhub-lb.com/results/#/${linkID} `
    );
    // alert("Text copied");
  };

  useEffect(() => {
    if (regionName === "All") {
      setNoRegion(true);
    }
  }, [regionName]);

  useEffect(() => {
    if (data && mohafazaName === casaName) {
      setMohafazaCasa(true);
    }
  }, [casaName, mohafazaName]);

  useEffect(() => {
    setDurationValue(value);
  }, [value]);

  useEffect(() => {
    setDuration((batteryBankSize * 1000 * dod) / (220 * durationValue));
  }, [value, dod, batteryBankSize, durationValue]);

  function screenshot() {
    setHideButtons(true);
    const input = document.getElementById("results-page");
    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let a = document.createElement("a");
        a.download = "undp-calculator-results.jpg";
        a.href = canvas.toDataURL("image/jpg");
        a.click("JPG", 0, 0, imgWidth, imgHeight);
        setHideButtons(false);
      });
    }, 1000);
  }

  useEffect(() => {
    if (people != 0 && rooms != 0) {
      setPeopleText(` and ${people} people`);
    } else if (people != 0 && rooms == 0) {
      setPeopleText(` with ${people} people`);
    }
  }, [people, rooms]);

  useEffect(() => {
    if (rooms != 0) {
      setRoomsText(` with ${rooms} rooms`);
    }
  }, [rooms]);

  return (
    <div>
      <div className="results-page pb-3 mx-lg-auto" id="results-page">
        <h1
          className={`text-light pt-5 start-text mx-2 ${mohafazaCasa === true &&
            "d-none"}`}
        >
          {noRegion === false
            ? `Your house in ${regionName}, ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`
            : `Your house in ${mohafazaName}, ${casaName}${roomsText}${peopleText}.`}
        </h1>
        <h1
          className={`text-light pt-5 start-text mx-2 ${mohafazaCasa ===
            false && "d-none"}`}
        >
          {mohafazaCasa === true
            ? `Your house in ${casaName}${roomsText}${peopleText}.`
            : ""}
        </h1>
        <h3 className="pt-4 text-light">
          Based on your inputs, you will need:
        </h3>
        <div className="d-flex row justify-content-evenly m-auto mx-4">
          {results.map(({ image, number, unit, title, text }, index) => {
            return (
              <ResultCard
                image={image}
                title={title}
                unit={unit}
                number={number}
                key={index}
                text={text}
              />
            );
          })}
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default Results;
