import React, { useState, useEffect } from "react";
import Categories from "./shared/Categories";
import lighting from "../assets/images/lighting.svg";
import heat from "../assets/images/heat.svg";
import kitchen from "../assets/images/kitchen.svg";
import tv from "../assets/images/tv.svg";
import laundry from "../assets/images/laundry.svg";
import IT from "../assets/images/IT.svg";
import selfcare from "../assets/images/selfcare.svg";
import AppliancesCard from "./shared/AppliancesCard";
import AddApplianceModal from "./shared/AddApplianceModal";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function Appliances() {
  const location = useLocation();
  const navigate = useNavigate();
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
      const [aaa, setAaa] = useState("");

  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [categoryClicked, setCategoryClicked] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [appliances, setAppliances] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [indexNew, setIndexNew] = useState("");
  const [clicks, setClicks] = useState([]);
  const [clicksAdd, setClicksAdd] = useState([]);
  const [changed, setChanged] = useState(false);
  const [newAppliance, setNewAppliance] = useState([]);
  const [modalDefaultOpen, setModalDefaultOpen] = useState(false);
  const [appliance, setAppliance] = useState([]);
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [applianceType, setApplianceType] = useState("");
  const [powerRating, setPowerRating] = useState("");
  const [maxWattage, setMaxWattage] = useState("");
  const [validData, setValidData] = useState(true);
  const [objectNew, setObjectNew] = useState([]);
    const [kwh, setKwh] = useState("");
    const [usd, setUsd] = useState("");
    const [savings, setSavings] = useState("");
    const [items, setItems] = useState([]);


  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setEnergy_manager(location.state.energy_manager);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
       setKwh(location.state.kwh);
       setAaa(location.state.aaa);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setItems(location.state.items);
      if (location.state.indexNew) {
        setIndexNew(location.state.indexNew);
      }
      if (location.state.appliance) {
        setAppliance(location.state.appliance);
      }
      if (location.state.appliancesUpdates) {
        setAppliancesUpdates(location.state.appliancesUpdates);
      }
      if (location.state.clicksAdd) {
        setClicksAdd(location.state.clicksAdd);
      }
    }
  }, [location.state]);

 const ids = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94];

  const category = [
    {
      image: <img src={lighting} alt="" className="pt-2" />,
      title: <p>Lighting</p>,
      id: 5,
    },
    {
      image: <img src={heat} alt="" className="mt-3 inactive-image " />,
      title: (
        <p>
          Heating/
          <br />
          Cooling
        </p>
      ),
      id: 1,
    },
    {
      image: <img src={kitchen} alt="" className="pt-2 inactive-image " />,
      title: <p>Kitchen</p>,
      id: 4,
    },
    {
      image: <img src={tv} alt="" className="pt-3 inactive-image " />,
      title: <p>Entertainment</p>,
      id: 2,
    },
    {
      image: <img src={laundry} alt="" className="pt-2 inactive-image " />,
      title: (
        <p>
          Laundry/
          <br />
          Houshold
        </p>
      ),
      id: 6,
    },
    {
      image: <img src={IT} alt="" className="pt-3 inactive-image " />,
      title: <p>IT</p>,
      id: 3,
    },
    {
      image: <img src={selfcare} alt="" className="pt-2 inactive-image " />,
      title: <p>Selfcare</p>,
      id: 7,
    },
  ];


  useEffect(() => {
console.log(appliancesUpdates);
  }, [ appliancesUpdates]);

  useEffect(() => {
    setCategoryClicked((state) => [...state, categoryId]);
  }, [categoryId]);

  useEffect(() => {
    ids.map((item) => {
      appliancesUpdates.push({
        id: item,
        week_day: "day",
        daytime_hrs: 0,
        nighttime_hrs: 0,
        daytime_qty: 0,
        nighttime_qty: 0,
      });
      return appliancesUpdates;
    });
  }, []);

  useEffect(() => {
    let uniqueCategories = [
      ...new Set(categoryClicked.filter((str) => str !== "")),
    ];
    let answer = uniqueCategories.includes(categoryId);
    if (answer === false && categoryId) {
      axios
        .get(
          `https://apisolarcalculator.energyhub-lb.com/api.php?action=appliances&categoryId=${categoryId}`
        )
        .then((res) => {
          setAppliances(Object.entries(res.data.data));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [categoryId]);

  useEffect(() => {
    appliances.map((value) => {
      newAppliance.push({
        categoriesId: categoryId,
        id: value[0],
        title: value[1],
        week_day: "day",
        daytime_hrs: 0,
        nighttime_hrs: 0,
        daytime_qty: 0,
        nighttime_qty: 0,
      });
      setChanged(!changed);
      return appliances;
    });
  }, [appliances]);

    const obj = objectNew.reduce((acc, cur, index) => {
      acc[cur.id] = { index: cur };
      return acc;
    }, {});
    var output = Object.values(obj).sort((a, b) => a.index - b.index).map(({ index: val }) => val)
  
  let tips = [];
  const submit = (e) => {
    if ((applianceType && powerRating && maxWattage) || clicks.length === 0) {
      e.preventDefault();
      setModalOpen(false);
      setClicks([]);      

      if (output.length === 0 && indexNew !== 6) {
        setIndexNew(indexNew + 1);
      } else {
        axios
          .post(
            `https://apisolarcalculator.energyhub-lb.com/api.php?action=tips`,
            output
          )
          .then((res) => {
            console.log("res",res.data.data);
            
            tips = res.data.data;
          })
          .catch((err) => {
            console.log("error", err);
          })
          .then(() => {
            if (indexNew === 6 && tips.length !== 0) {
              navigate("/batteries", {
                state: {
                  indexNew: indexNew,
                  rooms: rooms,
                  people: people,
                  age: age,
                  gender: gender,
                  manager: manager,
                  energy_manager: energy_manager,
                  appliancesUpdates: appliancesUpdates,
                  appliance: appliance,
                  mohafazaName: mohafazaName,
                  casaName: casaName,
                  regionName: regionName,
                  areaPicked: areaPicked,
                  clicksAdd: clicksAdd,
                  tips: tips,

                  items: items,
                  kwh: kwh,
                  aaa:aaa,
                  usd: usd,
                  savings: savings,
                },
              });
            } else {
              setIndexNew(indexNew + 1);
            }
            if(indexNew === 6 && tips.length === 0){
               navigate("/batteries", {
                 state: {
                   rooms: rooms,
                   people: people,
                   age: age,
                   gender: gender,
                   manager: manager,
                   energy_manager: energy_manager,
                   appliancesUpdates: appliancesUpdates,
                   appliance: appliance,
                   mohafazaName: mohafazaName,
                   casaName: casaName,
                   regionName: regionName,
                   areaPicked: areaPicked,

                   items: items,
                   kwh: kwh,
                     aaa:aaa,
                   usd: usd,
                   savings: savings,
                 },
               });
            }
          });
      }
    } else {
      setValidData(false);
    }
  };

  
  useEffect(() => {
    if (indexNew !== 0) {
      setModalDefaultOpen(true);
    } else {
      setModalDefaultOpen(false);
    }
  }, [indexNew]);

  return (
    <div>
      <div className="appliances-page mx-lg-auto pt-3">
        <div
          className={`d-block appliances-modal modal show fade pt-3 ${modalDefaultOpen ===
            true && "d-none"}`}
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          role="dialog"
          aria-modal="true"
        >
          <div className="modal-dialog mx-2 mx-lg-auto mx-md-auto mx-sm-auto">
            <div className="modal-content">
              <div className="modal-header border-0 pt-2 pe-2">
                <button
                  type="button"
                  className="btn-close p-3 shadow-none"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalDefaultOpen(true);
                  }}
                ></button>
              </div>
              <div className="modal-body mb-3 p-0 ">
                <h3 className="lh-sm text-start location-text px-3 pt-2 mb-1">
                  Power rating is measured in watts (W) or kilowatts (kW). This
                  information can be found on the appliance's label or in the
                  manual.
                </h3>
                <h3 className="lh-sm text-start location-text px-3 pt-4 mb-1">
                  Some appliances are used once a week and some are used every
                  day or every night or all the time, so try to be as accurate
                  as possible.
                </h3>
              </div>
              <div className="modal-footer mb-5 border-0">
                <div className="m-auto mb-4">
                  <button
                    type="button"
                    className="btn btn-submit rounded-pill"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setModalDefaultOpen(true);
                    }}
                  >
                    I am ready!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`header-fixed ${modalOpen && "position-absolute"}`}>
          <h1 className="p-3 text-start mb-0">
            Some fields are automatically filled based on size of home, feel
            free to change them.
          </h1>
          <div
            className="d-flex px-3 overflow"
            onClick={() => {
              setModalOpen(false);
              setClicks([]);
            }}
          >
            {category.map(({ image, title, id }, index) => {
              return (
                <li
                  onClick={() => {
                    setIndexNew(index);
                  }}
                  key={index}
                  className="list-unstyled"
                >
                  <Categories
                    image={image}
                    title={title}
                    key={index}
                    id={id}
                    index={index}
                    indexNew={indexNew}
                    setIndexNew={setIndexNew}
                    setCategoryId={setCategoryId}
                  />
                </li>
              );
            })}
          </div>
        </div>

        {modalOpen !== true ? (
          <div className="mb-3">
            {newAppliance.map((item, index) => {
              if (categoryId === item.categoriesId) {
                return (
                  <AppliancesCard
                    title={item.title}
                    key={index}
                    id={item.id}
                    appliancesUpdates={appliancesUpdates}
                    setAppliancesUpdates={setAppliancesUpdates}
                    categoryId={categoryId}
                    objectNew={objectNew}
                    setObjectNew={setObjectNew}
                  />
                );
              }
            })}
          </div>
        ) : (
          ""
        )}
        {modalOpen ? (
          <div className="position-modal">
            {clicks.map((count) => {
              return (
                <AddApplianceModal
                  key={count}
                  appliance={appliance}
                  setAppliance={setAppliance}
                  id={clicksAdd.length}
                  setApplianceType={setApplianceType}
                  setPowerRating={setPowerRating}
                  setMaxWattage={setMaxWattage}
                  validData={validData}
                  setModalOpen={setModalOpen}
                  setClicks={setClicks}
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
        <p className="miss-text mb-1 mt-4">
          Did we miss any appliance you might be using?
        </p>
        <button
          className="location-btn rounded-pill mb-4 me-lg-2 me-md-2 me-sm-2"
          type="button"
          onClick={() => {
            setModalOpen(true);
            setClicks((current) => [...current, "appliance"]);
            setClicksAdd((current) => [...current, "new"]);
            window.scrollTo(0, 0);
            appliance.push({
              applianceId: clicksAdd.length + 1,
              appliance_type: "",
              power_rating: "",
              max_wattage: "",
              week_day: "day",
              daytime_hrs: 0,
              nighttime_hrs: 0,
              daytime_qty: 0,
              nighttime_qty: 0,
            });
          }}
        >
          <span className="align-middle">Add Other Appliances</span>
          <span className="appliances-add-btn ms-3 align-middle">+</span>
        </button>
        <button
          className="next-btn rounded-pill mb-2 mt-3"
          type="button"
          onClick={submit}
        >
          Next
        </button>

        <div className="d-flex padding-bottom justify-content-center position-sticky background-box">
          <div
            className={`active box`}
            onClick={() => {
              navigate("/home-details", {
                state: {
                  rooms: rooms,
                  people: people,
                  age: age,
                  gender: gender,
                  manager: manager,
                  energy_manager: energy_manager,
                  mohafazaName: mohafazaName,
                  casaName: casaName,
                  regionName: regionName,
                  areaPicked: areaPicked,
                },
              });
            }}
          ></div>
          <div
            className={`active box`}
            onClick={() => {
              navigate("/energy-manager", {
                state: {
                  rooms: rooms,
                  people: people,
                  age: age,
                  gender: gender,
                  manager: manager,
                  energy_manager: energy_manager,
                  mohafazaName: mohafazaName,
                  casaName: casaName,
                  regionName: regionName,
                  areaPicked: areaPicked,
                },
              });
            }}
          ></div>
          <div className={`active box`}></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
        </div>
      </div>
    </div>
  );
}

export default Appliances;
