import React, { useEffect, useState } from "react";
import loading from "../assets/GIF/loading.gif";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function CalculatingAr() {
  const navigate = useNavigate();
  const location = useLocation();
  const [object, setObject] = useState("");
  const [people, setPeople] = useState("");
  const [rooms, setRooms] = useState("");

  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [battery, setBattery] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [newSimpleApp, setNewSimpleApp] = useState("");
  const [appliancesUpdates, setAppliancesUpdates] = useState([]);
  const [areaPicked, setAreaPicked] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState();
  const [energy_sources_result, setEnergy_sources_result] = useState("");
  const [modalDefaultOpen, setModalDefaultOpen] = useState(false);

  const [items, setItems] = useState([]);
  const [kwh, setKwh] = useState("");
  const [aaa, setAaa] = useState("");
  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");

  let data = [];

  // useEffect(() => {
  //   if (newSimpleApp) {
  //     const updatedData = newSimpleApp.keys(data).reduce((acc, key) => {
  //       const { increased, ...rest } = data[key];
  //       acc[key] = rest;
  //       return acc;
  //     }, {});
  //     setNewSimpleApp(updatedData);
  //   }
  // }, [newSimpleApp]);

  useEffect(() => {
    if (location.state) {
      setAreaPicked(location.state.areaPicked);
      setAge(location.state.age);
      setGender(location.state.gender);
      setManager(location.state.manager);
      setBattery(location.state.battery);
      setEnergy_manager(location.state.energy_manager);
      setAppliance(location.state.appliance);
      setAppliancesUpdates(location.state.appliancesUpdates);
      setRooms(location.state.rooms);
      setPeople(location.state.people);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
      setItems(location.state.items);
      setKwh(location.state.kwh);
      setAaa(location.state.aaa);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      if (location.state.simpleApp) {
        setNewSimpleApp(location.state.simpleApp);
      }
    }
  }, [location.state]);

  useEffect(() => {
    console.log(object);
  }, [object]);

  useEffect(() => {
    if (kwh && usd && savings && aaa) {
      setEnergy_sources_result(
        `Generating ${kwh} KWH/year, saving ${usd} USD/year, reducing ${savings} Kg CO2/year, If you upgrade your solar system you can save more than ${aaa} USD/year`
      );
    } else if (kwh && usd && savings) {
      setEnergy_sources_result(
        `Generating ${kwh} KWH/year, saving ${usd} USD/year, reducing ${savings} Kg CO2/year`
      );
    }
  }, [kwh, usd, savings, aaa]);

  useEffect(() => {
    if (savings && !kwh && !usd && !aaa) {
      setEnergy_sources_result(
        `If you move to solar system you can save more than ${savings} USD/year`
      );
    }
  }, [kwh, usd, savings, aaa]);

  useEffect(() => {
    if (regionName === "" && !location.state) {
      navigate("/");
    }
  }, [regionName]);

  const submit = (e) => {
    e.preventDefault();
    const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (regExp.test(email)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  useEffect(() => {
    if (emailIsValid === true && object !== "") {
      axios
        .post(
          `https://apisolarcalculator.energyhub-lb.com/api.php?action=save&lang=ar`,
          object
        )
        .then((res) => {
          console.log(res.data.data);
          data.push(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .then(() => {
          navigate("/results-ar", {
            state: {
              rooms: rooms,
              people: people,
              mohafazaName: mohafazaName,
              casaName: casaName,
              regionName: regionName,
              data: data,
              newSimpleApp: newSimpleApp,
            },
          });
        });
    }
  }, [emailIsValid, object]);

  const skipSubmit = (e) => {
    e.preventDefault();
    if (object) {
      axios
        .post(
          `https://apisolarcalculator.energyhub-lb.com/api.php?action=save&lang=ar`,
          object
        )
        .then((res) => {
          console.log(res.data.data);
          data.push(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .then(() => {
          navigate("/results-ar", {
            state: {
              rooms: rooms,
              people: people,
              mohafazaName: mohafazaName,
              casaName: casaName,
              regionName: regionName,
              data: data,
              newSimpleApp: newSimpleApp,
            },
          });
        });
    }
  };

  useEffect(() => {
    if (appliancesUpdates) {
      setObject([
        [
          { region_id: areaPicked },
          { rooms: rooms },
          { people: people },
          { age: age },
          { gender: gender },
          { manager: energy_manager },
          { manager_option: manager },
          { battery: battery },
          { name: name },
          { email: email },
          { lang: "ar" },
        ],
        [
          { energy_sources: items },
          { energy_sources_result: energy_sources_result },
        ],
        appliancesUpdates,
        appliance,
      ]);
    } else {
      setObject([
        [
          { region_id: areaPicked },
          { rooms: rooms },
          { people: people },
          { age: age },
          { gender: gender },
          { manager: energy_manager },
          { manager_option: manager },
          { battery: battery },
          { name: name },
          { email: email },
          { lang: "ar" },
        ],
        [
          { energy_sources: items },
          { energy_sources_result: energy_sources_result },
        ],
        newSimpleApp,
      ]);
    }
  }, [
    rooms,
    people,
    age,
    gender,
    manager,
    battery,
    appliance,
    name,
    email,
    energy_sources_result,
  ]);
  useEffect(() => {
    setTimeout(() => {
      setModalDefaultOpen(true);
    }, 5000);
  }, []);

  return (
    <div dir="rtl">
      <div className="calculating-page mx-lg-auto pt-5">
        {modalDefaultOpen === false ? (
          <>
            <div className="homepage-image-height">
              <img src={loading} alt="" className=" pt-4 " width="300" />
            </div>
            <h1 className="mx-4 mt-4 font-arabic-regular">
              نحن نحسب استهلاكك للطاقة الشمسية. <br />
              الرجاء الانتظار…
            </h1>
          </>
        ) : (
          <>
            <div className="modal-dialog d-block mx-2">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="fs-5" id="modalLabel"></div>
                  <button
                    type="button"
                    className="btn-close p-3"
                    onClick={skipSubmit}
                    aria-label="Close"
                  ></button>
                </div>
                <form>
                  <div className="modal-body">
                    <h1 className="lh-sm text-end location-text px-3 pt-2 mb-1 font-arabic-regular">
                      هل ترغب في تلقي نصائح حول كيفية تحقيق أقصى استفادة من
                      نظامك الشمسي؟
                    </h1>
                    <p className="text-end pe-3 miss-text pt-2 font-arabic-regular">
                      أدخل اسمك وبريدك الإلكتروني أدناه{" "}
                    </p>
                    <div>
                      <input
                        type="text"
                        name="name"
                        placeholder="الاسم"
                        className="popup-input mb-4 pe-4 miss-text font-arabic-regular"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="email"
                        placeholder="البريد الإلكتروني"
                        className="popup-input mb-2 pe-4 miss-text font-arabic-regular"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {emailIsValid === false ? (
                      <p className="text-start ms-4 email-invalid-message mt-0 text-lg-center text-md-center text-sm-center font-arabic-regular">
                        البريد الإلكتروني غير صالح!
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer mt-3">
                    <div className="m-auto">
                      <button
                        type="submit"
                        className="btn btn-submit rounded-pill font-arabic-regular"
                        onClick={submit}
                      >
                        الإرسال وعرض النتائج
                      </button>
                    </div>
                    <p className=" try-text mt-3 mb-4">
                      <Link
                        to="/results"
                        className={
                          "text-muted text-decoration-none font-arabic-regular"
                        }
                        onClick={skipSubmit}
                      >
                        كلا، لست مهتماً. <br /> انقلني إلى نتائج.
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CalculatingAr;
