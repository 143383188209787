import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function AppliancesSimplifiedAr() {
  const location = useLocation();
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [aaa, setAaa] = useState("");

  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [items, setItems] = useState([]);
  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [kwh, setKwh] = useState("");
  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");

  useEffect(() => {
    if (location.state) {
      setKwh(location.state.kwh);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setAge(location.state.age);
      setPeople(location.state.people);
      setRooms(location.state.rooms);
      setGender(location.state.gender);
      setEnergy_manager(location.state.energy_manager);
      setManager(location.state.manager);
      setItems(location.state.items);
      setAaa(location.state.aaa);
      setAreaPicked(location.state.areaPicked);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
    }
  }, [location.state]);

  const [simpleApp, setSimpleApp] = useState([
    {
      id: 66,
      name: "لمبة LED متوسطة",
      daytime_qty: 0,
      daytime_hrs: 12,
      max_wattage: 12,
    },
    {
      id: 75,
      name: "لمبة متوجهة  متوسطة",
      daytime_qty: 0,
      daytime_hrs: 0,
      max_wattage: 60,
    },
    {
      id: 85,
      name: "مجففة ملابس",
      daytime_qty: 0,
      daytime_hrs: 1,
      max_wattage: 4000,
    },
    {
      id: 27,
      name: "تلفزيون",
      daytime_qty: 0,
      daytime_hrs: 6.5,
      max_wattage: 100,
    },
    {
      id: 86,
      name: "غسالة",
      daytime_qty: 0,
      daytime_hrs: 1,
      max_wattage: 2200,
    },
    {
      id: 55,
      name: "ميكروويف",
      daytime_qty: 0,
      daytime_hrs: 0.1,
      max_wattage: 2500,
    },
    {
      id: 1,
      name: "مكيف الهواء",
      daytime_qty: 0,
      daytime_hrs: 5,
      max_wattage: 2600,
    },
    {
      id: 53,
      name: "ثلاجة",
      daytime_qty: 0,
      daytime_hrs: 24,
      max_wattage: 400,
    },
  ]);
  const handleIncrement = (id) => {
    setSimpleApp((prevState) =>
      prevState.map((appliance) =>
        appliance.id === id
          ? { ...appliance, daytime_qty: appliance.daytime_qty + 1 }
          : appliance
      )
    );
  };

  const handleDecrement = (id) => {
    setSimpleApp((prevState) =>
      prevState.map((appliance) =>
        appliance.id === id && appliance.daytime_qty > 0
          ? { ...appliance, daytime_qty: appliance.daytime_qty - 1 }
          : appliance
      )
    );
  };

  return (
    <div dir="rtl">
      <div className="appliances-simplified-page mx-lg-auto">
        <h4 className="mb-3 font-arabic-regular">أجهزتي المنزلية الحالية</h4>
        {simpleApp.map((appliance) => (
          <div className="appliance-item mb-4" key={appliance.id}>
            <p className="font-arabic-regular">{appliance.name}</p>
            <div className="appliance-qty">
              <div
                className="qty-circle"
                onClick={() => handleDecrement(appliance.id)}
              >
                -
              </div>
              <p
                className={`align-items-center d-flex qty-number mx-3 ${appliance.daytime_qty >
                  0 && "increased"}`}
              >
                {appliance.daytime_qty}
              </p>

              <div
                className="qty-circle"
                onClick={() => handleIncrement(appliance.id)}
              >
                +
              </div>
            </div>
          </div>
        ))}

        <Link
          to="/calculating-ar"
          state={{
            simpleApp: simpleApp,
            age: age,
            people: people,
            rooms: rooms,
            gender: gender,
            energy_manager: energy_manager,
            manager: manager,
            items: items,
            areaPicked: areaPicked,
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,
            kwh: kwh,
            aaa: aaa,
            usd: usd,
            savings: savings,
          }}
        >
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-submit rounded-pill font-arabic-regular"
              // onClick={submit}
            >
              احسب
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AppliancesSimplifiedAr;
