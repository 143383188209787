import React, { useState } from "react";

function ResultCardAr({ image, number, unit, title, text }) {
  const [cardClicked, setCardClicked] = useState(false);

  return (
    <>
      {number.props.children !== "nan" ? (
        <div
          className={`result-card col-6 mb-2 text-start ${cardClicked ===
            true && "result-card-clicked direction"}`}
          onClick={() => {
            setCardClicked(!cardClicked);
          }}
        >
          {cardClicked ? (
            <p className="result-card-text pt-3 px-2 font-arabic-regular direction text-end">
              {text}
            </p>
          ) : (
            <>
              <div className="d-flex justify-content-between direction">
                <div className="mt-3 font-arabic-semibold direction text-end">
                  {number}
                  <p className="unit font-arabic-semibold direction">{unit}</p>
                </div>
                <div>{image}</div>
              </div>
              <p className="result-text font-arabic-semibold direction text-end">
                {title}
              </p>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ResultCardAr;
