import React, { useState } from "react";

function ResultCard({ image, number, unit, title, text }) {
  const [cardClicked, setCardClicked] = useState(false);

  return (
    <>
      {number.props.children !== "nan" ? (
        <div
          className={`result-card col-6 mb-2 text-start ${cardClicked ===
            true && "result-card-clicked"}`}
          onClick={() => {
            setCardClicked(!cardClicked);
          }}
        >
          {cardClicked ? (
            <p className="result-card-text pt-3 px-2">{text}</p>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div className="mt-3">
                  {number}
                  <p className="unit">{unit}</p>
                </div>
                <div>{image}</div>
              </div>
              <p className="result-text">{title}</p>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ResultCard;
