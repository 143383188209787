import React, { useState,useEffect } from "react";
import daytime from "../../assets/images/daytime.svg";
import nighttime from "../../assets/images/nighttime.svg";

function AddApplianceModalAr({
  appliance,
  setAppliance,
  id,
  setApplianceType,
  setPowerRating,
  setMaxWattage,
  validData,
  setModalOpen,
  setClicks,
}) {
  const [daytime_qty, setDaytime_qty] = useState(0);
  const [nighttime_qty, setNighttime_qty] = useState(0);
  const [active, setActive] = useState(true);
  const [activeHrs, setActiveHrs] = useState(false);
  const [dayHrsClicked, setDayHrsClicked] = useState(false);

  function allowOnlyNumbersNight(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      const newArr = appliance.map((obj) => {
        if (id === obj.applianceId) {
          return {
            ...obj,
            nighttime_hrs: e.target.value,
          };
        }
        return obj;
      });
      setAppliance(newArr);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  function allowOnlyNumbers(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      setMaxWattage(e.target.value);
      const newArr = appliance.map((obj) => {
        if (id === obj.applianceId) {
          return {
            ...obj,
            max_wattage: e.target.value,
          };
        }
        return obj;
      });
      setAppliance(newArr);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  function allowOnlyNumbersDay(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (test_result) {
      const newArr = appliance.map((obj) => {
        if (id === obj.applianceId) {
          return {
            ...obj,
            daytime_hrs: e.target.value,
          };
        }
        return obj;
      });
      setAppliance(newArr);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

   function increaseHrs() {
    const newAr = appliance.map((obj) => {
        return {
          ...obj,
          daytime_hrs: "12",
          nighttime_hrs: "12",
        };
    });
    setAppliance(newAr);
  }
  // 
  
  // 

  useEffect(() => {
    if (daytime_qty > 0) {
      setDayHrsClicked(true);
    } else {
      setDayHrsClicked(false);
    }
  }, [daytime_qty]);

  return (
    <div className="z-index2">
      <div className="modal-dialog appliance-border m-3">
        <div className="modal-header border-0 pt-2 pe-2 justify-content-between">
          <div></div>
          <button
            type="button"
            className="btn-close p-3 shadow-none"
            aria-label="Close"
            onClick={() => {
              setModalOpen(false);
              setClicks([]);
              setAppliance([]);
            }}
          ></button>
        </div>
        <div className="modal-content mt-4">
          <div className="mb-2">
            <input
              type="text"
              className="modal-input font-arabic-regular"
              placeholder="نوع الجهاز"
              onChange={(e) => {
                setApplianceType(e.target.value);
                const newArr = appliance.map((obj) => {
                  if (id === obj.applianceId) {
                    return {
                      ...obj,
                      appliance_type: e.target.value,
                    };
                  }
                  return obj;
                });
                setAppliance(newArr);
              }}
            />
          </div>
          <div className="mb-2">
            <input
              type="text"
              className="modal-input font-arabic-regular"
              placeholder="تصنيف الطاقة"
              onChange={(e) => {
                setPowerRating(e.target.value);
                const newArr = appliance.map((obj) => {
                  if (id === obj.applianceId) {
                    return {
                      ...obj,
                      power_rating: e.target.value,
                    };
                  }
                  return obj;
                });
                setAppliance(newArr);
              }}
            />
          </div>
          <div>
            <input
              type="tel"
              className="modal-input font-arabic-regular"
              placeholder="القوة الكهربائية"
              onChange={(e) => {
                allowOnlyNumbers(e);
              }}
            />
          </div>
          {validData === false ? (
            <p className="font-arabic-regular text-center email-invalid-message pt-2 direction">
              الرجاء تعبئة جميع المجالات!
            </p>
          ) : (
            ""
          )}
           {/*  */}
          <div className="d-flex justify-content-between switch-container">
            <div className="d-flex mt-1">
              <p
                className={`font-10 mb-0 pt-1 ${active === false && "active"}`}
              >
                أسبوعياً
              </p>
              <div className="form-switch mx-2 pt-1">
                <input
                  className="form-check-input check-input"
                  type="checkbox"
                  defaultChecked
                  onClick={(e) => {
                    setActive(e.target.checked);
                    const newArr = appliance.map((obj) => {
                      if (id === obj.applianceId) {
                        return {
                          ...obj,
                          week_day: e.target.checked === true ? "day" : "week",
                        };
                      }
                      return obj;
                    });
                    setAppliance(newArr);
                  }}
                />
              </div>
              <p className={`font-10 mb-0 pt-1 ${active === true && "active"}`}>
                في اليوم الواحد
              </p>
            </div>
            {/*  */}
            <div className="d-flex align-items-center float-end">
              <div className="form-switch mx-1">
                <input
                  className="form-check-input check-input load-input"
                  type="checkbox"
                  name="daytime_hrs"
                  id="flexSwitchCheckChecked"
                  onClick={(e) => {
                    setActiveHrs(!activeHrs);
                    increaseHrs();
                  }}
                />
              </div>
              <p
                className={`load-text font-10 mb-0 pt-1 ${activeHrs === true &&
                  "active"}`}
              >
                  مستمر التحميل (24 ساعة)
              </p>
            </div>
          </div>
          {/*  */}
          <div className="appliances-card-grid">
            <div className="daytime-section">
              <div className="text-center mt-1">
                <div className="d-inline-block me-2">
                  <img src={daytime} alt="" />
                </div>
                <div className="d-inline-block daytime-text">نهارا</div>
              </div>

              <div className="d-flex align-items-center justify-content-center mb-2">
                <button
                  className="btn-next ps-0"
                  type="button"
                  onClick={() => {
                    if (daytime_qty > 0) {
                      setDaytime_qty(daytime_qty - 1);
                      const newArr = appliance.map((obj) => {
                        if (id === obj.applianceId) {
                          return {
                            ...obj,
                            daytime_qty: daytime_qty - 1,
                          };
                        }
                        return obj;
                      });
                      setAppliance(newArr);
                    }
                  }}
                >
                  <div className="daytime-circle">-</div>
                </button>
                <p className="mb-0 align-items-center d-flex mx-3 count-number">
                  {daytime_qty}
                </p>

                <button
                  className="btn-next"
                  type="button"
                  onClick={() => {
                    setDaytime_qty(daytime_qty + 1);
                    const newArr = appliance.map((obj) => {
                      if (id === obj.applianceId) {
                        return {
                          ...obj,
                          daytime_qty: daytime_qty + 1,
                        };
                      }
                      return obj;
                    });
                    setAppliance(newArr);
                  }}
                >
                  <div className="daytime-circle">+</div>
                </button>
              </div>

              {dayHrsClicked || activeHrs ? (
                <div className="d-flex mb-2 justify-content-center">
                  <div className="hrs-input-div ">
                    <input
                      placeholder="0"
                      min={0}
                      type="tel"
                      name="daytime_hrs"
                      className="hrs-input"
                      defaultValue={activeHrs ? 12 : ""}
                      onKeyPress={(e) => {
                        if (e.code === "Minus") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        allowOnlyNumbersDay(e);
                      }}
                    />
                  </div>

                  <p className="hrs mb-0 ps-1 pt-0">ساعات</p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="nighttime-section">
              <div className="text-center mt-1">
                <div className="d-inline-block me-2">
                  <img src={nighttime} alt="" />
                </div>
                <div className="d-inline-block nighttime-text">ليلا</div>
              </div>

              <div className="d-flex align-items-center justify-content-center mb-2">
                <button
                  className="btn-next ps-0"
                  type="button"
                  onClick={() => {
                    if (nighttime_qty > 0) {
                      setNighttime_qty(nighttime_qty - 1);
                      const newArr = appliance.map((obj) => {
                        if (id === obj.applianceId) {
                          return {
                            ...obj,
                            nighttime_qty: nighttime_qty - 1,
                          };
                        }
                        return obj;
                      });
                      setAppliance(newArr);
                    }
                  }}
                >
                  <div className="nighttime-circle">-</div>
                </button>
                <p className="mb-0 align-items-center d-flex mx-3 nighttime-number">
                  {nighttime_qty}
                </p>

                <button
                  className="btn-next"
                  type="button"
                  onClick={() => {
                    setNighttime_qty(nighttime_qty + 1);
                    const newArr = appliance.map((obj) => {
                      if (id === obj.applianceId) {
                        return {
                          ...obj,
                          nighttime_qty: nighttime_qty + 1,
                        };
                      }
                      return obj;
                    });
                    setAppliance(newArr);
                  }}
                >
                  <div className="nighttime-circle">+</div>
                </button>
              </div>
              {dayHrsClicked || activeHrs ? (
                <div className="d-flex mb-2 justify-content-center">
                  <div className="hrs-input-div">
                    <input
                      placeholder="0"
                      min={0}
                      type="tel"
                      name="nighttime_hrs"
                      className="hrs-nighttime-input"
                      defaultValue={activeHrs ? 12 : ""}
                      onKeyPress={(e) => {
                        if (e.code === "Minus") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        allowOnlyNumbersNight(e);
                      }}
                    />
                  </div>

                  <p className="hrs mb-0 ps-1 pt-0">ساعات</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddApplianceModalAr;
