import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import books from "../assets/images/books.svg";

function HomeDetailsAr() {
  const location = useLocation();
  const navigate = useNavigate();
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [dataEntered, setDataEntered] = useState(false);
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [areaPicked, setAreaPicked] = useState("");
  const [items, setItems] = useState([]);
  const [electricityPicked, setElectricityPicked] = useState(false);
  const [generatorPicked, setGeneratorPicked] = useState(false);
  const [systemPicked, setSystemPicked] = useState(false);
  const [privateGeneratorPicked, setPrivateGeneratorPicked] = useState(false);

  useEffect(() => {
    setElectricityPicked(location.state.electricityNewPicked);
    setGeneratorPicked(location.state.generatorNewPicked);
    setSystemPicked(location.state.systemNewPicked);
    setPrivateGeneratorPicked(location.state.privateGeneratorNewPicked);
  }, [location.state]);

  useEffect(() => {
    if (location.state.items) {
      setItems(location.state.items);
    }
  }, [location.state.items]);

  useEffect(() => {
    setRegionName(location.state.regionName);
    setCasaName(location.state.casaName);
    setMohafazaName(location.state.mohafazaName);
    setAreaPicked(location.state.areaPicked);
  }, [
    location.state.casaName,
    location.state.areaPicked,
    location.state.mohafazaName,
    location.state.regionName,
  ]);

  useEffect(() => {
    if (rooms && people) {
      setDataEntered(true);
    } else {
      setDataEntered(false);
    }
  }, [rooms, people]);

  useEffect(() => {
    setElectricityPicked(location.state.electricityNewPicked);
    setGeneratorPicked(location.state.generatorNewPicked);
    setSystemPicked(location.state.systemNewPicked);
    setPrivateGeneratorPicked(location.state.privateGeneratorNewPicked);
  }, [location.state]);

  useEffect(() => {
    if (regionName === "" && !location.state) {
      navigate("/");
    }
  }, [regionName]);

  function allowOnlyNumbersRooms(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (e.target.value === "") {
      setRooms("");
    }
    if (test_result) {
      setRooms(e.target.value);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }
  function allowOnlyNumbersPeople(e) {
    var regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    if (e.target.value === "") {
      setPeople("");
    }
    if (test_result) {
      setPeople(e.target.value);
    } else {
      e.target.value = e.target.defaultValue;
    }
  }

  return (
    <div>
      <div className="homedetails-page mx-lg-auto direction">
        <div className="homedetails-image-height">
          <img src={books} alt="" className="mt-lg-5 mt-md-5 mt-4" />
        </div>
        <h1 className="mt-lg-5 mt-md-5 mt-4 font-arabic-semibold">
          في هذا الموقع، لدينا
        </h1>
        <div className="d-flex justify-content-center">
          <h3 className="mt-4 mb-0 font-arabic-semibold"> منزل مكون من</h3>
          <div className="input-div mx-2">
            <input
              type="tel"
              name="rooms"
              className={`${rooms && `border-selected-input`}`}
              onChange={(e) => {
                allowOnlyNumbersRooms(e);
              }}
            />
          </div>

          <h3 className="mt-4 mb-0 font-arabic-semibold">غرف,</h3>
        </div>

        <div className="d-flex justify-content-center mt-2">
          <h3 className="mt-4 mb-0 font-arabic-semibold">يأوي</h3>
          <div className="input-div mx-2">
            <input
              type="tel"
              name="people"
              className={`${people && `border-selected-input`}`}
              onChange={(e) => {
                allowOnlyNumbersPeople(e);
              }}
            />
          </div>
          <h3 className=" mt-4 mb-0 font-arabic-semibold">شخصاً.</h3>
        </div>

        <p className="mt-4 mb-5 mx-2 text-dark font-arabic-regular">
          يساعدنا عدد الغرف وأنماط حياة الأشخاص المختلفين في منزل واحد واستخدام
          نفس مصدر الطاقة على تقديم تقديرات أكثر دقة لك.
        </p>

        <Link
          to="/energy-manager-ar"
          state={{
            rooms: rooms,
            people: people,
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,
            areaPicked: areaPicked,
            items: items,
            electricityPicked: electricityPicked,
            generatorPicked: generatorPicked,
            systemPicked: systemPicked,
            privateGeneratorPicked: privateGeneratorPicked,
          }}
        >
          <button
            className="next-btn rounded-pill font-arabic-regular"
            type="button"
            disabled={!dataEntered}
          >
            التالي
          </button>
        </Link>
        <div className="position-sticky footer-style">
          <Link
            to="/energy-manager-ar"
            className={"text-muted text-decoration-none"}
            state={{
              rooms: rooms,
              people: people,
              mohafazaName: mohafazaName,
              casaName: casaName,
              regionName: regionName,
              areaPicked: areaPicked,
              items: items,
              electricityPicked: electricityPicked,
              generatorPicked: generatorPicked,
              systemPicked: systemPicked,
              privateGeneratorPicked: privateGeneratorPicked,
            }}
          >
            <p className="pb-3 mt-3 mt-lg-5 mt-md-5 pt-3 font-arabic-regular">
              تخطى هذا الجزء
            </p>
          </Link>
          {dataEntered ? (
            <>
              <div className="d-flex padding-bottom justify-content-center direction">
                <div
                  className={`active box`}
                  onClick={() => {
                    navigate("/feedback");
                  }}
                ></div>
                <div className={`active box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
              </div>
            </>
          ) : (
            <>
              <div className="direction d-flex padding-bottom justify-content-center">
                <div
                  className={`active box`}
                  onClick={() => {
                    navigate("/feedback-ar");
                  }}
                ></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeDetailsAr;
