import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clock from "../assets/images/clock.svg";
import schedule from "../assets/images/schedule.svg";

function SolarEstimation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [simpleMethod, setSimpleMethod] = useState(false);
  const [detailedMethod, setDetailedMethod] = useState(false);
  const [rooms, setRooms] = useState("");
  const [people, setPeople] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [manager, setManager] = useState("");
  const [energy_manager, setEnergy_manager] = useState("");
  const [items, setItems] = useState([]);
  const [aaa, setAaa] = useState("");

  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [kwh, setKwh] = useState("");
  const [usd, setUsd] = useState("");
  const [savings, setSavings] = useState("");

  useEffect(() => {
    if (location.state) {
      setKwh(location.state.kwh);
      setAaa(location.state.aaa);
      setUsd(location.state.usd);
      setSavings(location.state.savings);
      setAge(location.state.age);
      setPeople(location.state.people);
      setRooms(location.state.rooms);
      setGender(location.state.gender);
      setEnergy_manager(location.state.energy_manager);
      setManager(location.state.manager);
      setItems(location.state.items);
      setAreaPicked(location.state.areaPicked);
      setMohafazaName(location.state.mohafazaName);
      setCasaName(location.state.casaName);
      setRegionName(location.state.regionName);
    }
  }, [location.state]);

  return (
    <div>
      <div className="solar-estimation-page mx-lg-auto">
        <p className="method-text">
          Let us help you estimate your solar energy system requirements based
          on your current household consumption.
        </p>
        <p className="method-text">Which method do you prefer?</p>
        <div
          className={`method-item ${simpleMethod && `picked`}`}
          onClick={(e) => {
            setSimpleMethod(true);
            navigate("/appliances-simplified", {
              state: {
                age: age,
                people: people,
                rooms: rooms,
                gender: gender,
                energy_manager: energy_manager,
                manager: manager,
                items: items,
                areaPicked: areaPicked,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                kwh: kwh,
                usd: usd,
                aaa: aaa,
                savings: savings,
              },
            });
          }}
        >
          <img src={clock} alt="clock" />
          <div>
            <h4>Simple based on quick averages </h4>
            <i>2-4 minutes to complete</i>
          </div>
        </div>

        <div
          className={`method-item detailed-method ${detailedMethod &&
            `picked`}`}
          onClick={(e) => {
            setDetailedMethod(true);
            navigate("/appliances", {
              state: {
                age: age,
                people: people,
                rooms: rooms,
                gender: gender,
                energy_manager: energy_manager,
                manager: manager,
                items: items,
                areaPicked: areaPicked,
                mohafazaName: mohafazaName,
                casaName: casaName,
                regionName: regionName,
                kwh: kwh,
                aaa: aaa,
                usd: usd,
                savings: savings,
              },
            });
          }}
        >
          <img src={schedule} alt="schedule" />
          <div>
            <h4>Detailed based on exact devices and usage</h4>
            <i>12-15 minutes to complete</i>
          </div>
        </div>

        <div className="d-flex padding-bottom justify-content-center">
          <div
            className={`active box`}
            onClick={() => {
              navigate("/feedback");
            }}
          ></div>
          <div className={`active box`}></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
          <div className={`inactive box`}></div>
        </div>
      </div>
    </div>
  );
}

export default SolarEstimation;
